import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Represents non-fractional signed whole numeric values. Since the value may exceed the size of a 32-bit integer, it's encoded as a string. */
  BigInt: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
};

/** Autogenerated input type of AcceptInvite */
export type AcceptInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  /** Uniq token of the Invite */
  token: Scalars['String'];
};

/** Autogenerated input type of AddGocardlessPaymentProvider */
export type AddGocardlessPaymentProviderInput = {
  accessCode?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type AddOn = {
  __typename?: 'AddOn';
  amountCents: Scalars['Int'];
  amountCurrency: CurrencyEnum;
  /** Check if add-on is deletable */
  canBeDeleted: Scalars['Boolean'];
  code: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  /** Number of customers using this add-on */
  customerCount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type AddOnCollection = {
  __typename?: 'AddOnCollection';
  collection: Array<AddOn>;
  metadata: CollectionMetadata;
};

export type AddOnDetails = {
  __typename?: 'AddOnDetails';
  amountCents: Scalars['Int'];
  amountCurrency: CurrencyEnum;
  /** Check if add-on is deletable */
  canBeDeleted: Scalars['Boolean'];
  code: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  /** Number of customers using this add-on */
  customerCount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of AddStripePaymentProvider */
export type AddStripePaymentProviderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  createCustomers?: InputMaybe<Scalars['Boolean']>;
  secretKey?: InputMaybe<Scalars['String']>;
};

export enum AggregationTypeEnum {
  CountAgg = 'count_agg',
  MaxAgg = 'max_agg',
  RecurringCountAgg = 'recurring_count_agg',
  SumAgg = 'sum_agg',
  UniqueCountAgg = 'unique_count_agg'
}

export type AppliedAddOn = {
  __typename?: 'AppliedAddOn';
  addOn: AddOn;
  amountCents: Scalars['Int'];
  amountCurrency: CurrencyEnum;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
};

export type AppliedCoupon = {
  __typename?: 'AppliedCoupon';
  amountCents?: Maybe<Scalars['Int']>;
  amountCurrency?: Maybe<CurrencyEnum>;
  coupon: Coupon;
  createdAt: Scalars['ISO8601DateTime'];
  frequency: CouponFrequency;
  frequencyDuration?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  percentageRate?: Maybe<Scalars['Float']>;
  terminatedAt: Scalars['ISO8601DateTime'];
};

export type BillableMetric = {
  __typename?: 'BillableMetric';
  aggregationType: AggregationTypeEnum;
  /** Check if billable metric is deletable */
  canBeDeleted: Scalars['Boolean'];
  code: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  flatGroups?: Maybe<Array<Group>>;
  group?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type BillableMetricCollection = {
  __typename?: 'BillableMetricCollection';
  collection: Array<BillableMetric>;
  metadata: CollectionMetadata;
};

export type BillableMetricDetail = {
  __typename?: 'BillableMetricDetail';
  aggregationType: AggregationTypeEnum;
  /** Check if billable metric is deletable */
  canBeDeleted: Scalars['Boolean'];
  code: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  flatGroups?: Maybe<Array<Group>>;
  group?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum BillingTimeEnum {
  Anniversary = 'anniversary',
  Calendar = 'calendar'
}

export type Charge = {
  __typename?: 'Charge';
  billableMetric: BillableMetric;
  chargeModel: ChargeModelEnum;
  createdAt: Scalars['ISO8601DateTime'];
  groupProperties?: Maybe<Array<GroupProperties>>;
  id: Scalars['ID'];
  properties?: Maybe<Properties>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ChargeInput = {
  billableMetricId: Scalars['ID'];
  chargeModel: ChargeModelEnum;
  groupProperties?: InputMaybe<Array<GroupPropertiesInput>>;
  id?: InputMaybe<Scalars['ID']>;
  properties?: InputMaybe<PropertiesInput>;
};

export enum ChargeModelEnum {
  Graduated = 'graduated',
  Package = 'package',
  Percentage = 'percentage',
  Standard = 'standard',
  Volume = 'volume'
}

export type ChargeUsage = {
  __typename?: 'ChargeUsage';
  amountCents: Scalars['BigInt'];
  billableMetric: BillableMetric;
  charge: Charge;
  groups?: Maybe<Array<GroupUsage>>;
  units: Scalars['Float'];
};

export type CollectionMetadata = {
  __typename?: 'CollectionMetadata';
  currentPage: Scalars['Int'];
  limitValue: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum CountryCode {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei Darussalam */
  Bn = 'BN',
  /** Bolivia (Plurinational State of) */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo (Democratic Republic of the) */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cabo Verde */
  Cv = 'CV',
  /** Curaçao */
  Cw = 'CW',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom of Great Britain and Northern Ireland */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong */
  Hk = 'HK',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran (Islamic Republic of) */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Korea (Democratic People's Republic of) */
  Kp = 'KP',
  /** Korea (Republic of) */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Lao People's Democratic Republic */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** Saint Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova (Republic of) */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** North Macedonia */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macao */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Pitcairn */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palestine, State of */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russian Federation */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** South Sudan */
  Ss = 'SS',
  /** Sao Tome and Principe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Syrian Arab Republic */
  Sy = 'SY',
  /** Eswatini */
  Sz = 'SZ',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Turkey */
  Tr = 'TR',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan, Province of China */
  Tw = 'TW',
  /** Tanzania, United Republic of */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Holy See */
  Va = 'VA',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Venezuela (Bolivarian Republic of) */
  Ve = 'VE',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Viet Nam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

export type Coupon = {
  __typename?: 'Coupon';
  amountCents?: Maybe<Scalars['Int']>;
  amountCurrency?: Maybe<CurrencyEnum>;
  /** Check if coupon is deletable */
  canBeDeleted: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
  couponType: CouponTypeEnum;
  createdAt: Scalars['ISO8601DateTime'];
  /** Number of customers using this coupon */
  customerCount: Scalars['Int'];
  expiration: CouponExpiration;
  expirationDate?: Maybe<Scalars['ISO8601Date']>;
  frequency: CouponFrequency;
  frequencyDuration?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  percentageRate?: Maybe<Scalars['Float']>;
  status: CouponStatusEnum;
  terminatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type CouponCollection = {
  __typename?: 'CouponCollection';
  collection: Array<Coupon>;
  metadata: CollectionMetadata;
};

export type CouponDetails = {
  __typename?: 'CouponDetails';
  amountCents?: Maybe<Scalars['Int']>;
  amountCurrency?: Maybe<CurrencyEnum>;
  /** Check if coupon is deletable */
  canBeDeleted: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
  couponType: CouponTypeEnum;
  createdAt: Scalars['ISO8601DateTime'];
  /** Number of customers using this coupon */
  customerCount: Scalars['Int'];
  expiration: CouponExpiration;
  expirationDate?: Maybe<Scalars['ISO8601Date']>;
  frequency: CouponFrequency;
  frequencyDuration?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  percentageRate?: Maybe<Scalars['Float']>;
  status: CouponStatusEnum;
  terminatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum CouponExpiration {
  NoExpiration = 'no_expiration',
  TimeLimit = 'time_limit'
}

export enum CouponFrequency {
  Once = 'once',
  Recurring = 'recurring'
}

export enum CouponStatusEnum {
  Active = 'active',
  Terminated = 'terminated'
}

export enum CouponTypeEnum {
  FixedAmount = 'fixed_amount',
  Percentage = 'percentage'
}

/** Autogenerated input type of CreateAddOn */
export type CreateAddOnInput = {
  amountCents: Scalars['Int'];
  amountCurrency: CurrencyEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Autogenerated input type of CreateAppliedAddOn */
export type CreateAppliedAddOnInput = {
  addOnId: Scalars['ID'];
  amountCents?: InputMaybe<Scalars['Int']>;
  amountCurrency?: InputMaybe<CurrencyEnum>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerId: Scalars['ID'];
};

/** Autogenerated input type of CreateAppliedCoupon */
export type CreateAppliedCouponInput = {
  amountCents?: InputMaybe<Scalars['Int']>;
  amountCurrency?: InputMaybe<CurrencyEnum>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  couponId: Scalars['ID'];
  customerId: Scalars['ID'];
  frequency?: InputMaybe<CouponFrequency>;
  frequencyDuration?: InputMaybe<Scalars['Int']>;
  percentageRate?: InputMaybe<Scalars['Float']>;
};

/** Autogenerated input type of CreateBillableMetric */
export type CreateBillableMetricInput = {
  aggregationType: AggregationTypeEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  fieldName?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
};

/** Autogenerated input type of CreateCoupon */
export type CreateCouponInput = {
  amountCents?: InputMaybe<Scalars['Int']>;
  amountCurrency?: InputMaybe<CurrencyEnum>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  couponType: CouponTypeEnum;
  expiration: CouponExpiration;
  expirationDate?: InputMaybe<Scalars['ISO8601Date']>;
  frequency: CouponFrequency;
  frequencyDuration?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  percentageRate?: InputMaybe<Scalars['Float']>;
};

/** Autogenerated input type of CreateCreditNote */
export type CreateCreditNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  invoiceId: Scalars['ID'];
  items: Array<CreditNoteItemInput>;
  reason: CreditNoteReasonEnum;
};

/** Autogenerated input type of CreateCustomer */
export type CreateCustomerInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  currency?: InputMaybe<CurrencyEnum>;
  email?: InputMaybe<Scalars['String']>;
  externalId: Scalars['String'];
  legalName?: InputMaybe<Scalars['String']>;
  legalNumber?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  paymentProvider?: InputMaybe<ProviderTypeEnum>;
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  stripeCustomer?: InputMaybe<StripeCustomerInput>;
  url?: InputMaybe<Scalars['String']>;
  vatRate?: InputMaybe<Scalars['Float']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateCustomerWallet */
export type CreateCustomerWalletInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currency: CurrencyEnum;
  customerId: Scalars['ID'];
  expirationDate?: InputMaybe<Scalars['ISO8601Date']>;
  grantedCredits: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  paidCredits: Scalars['String'];
  rateAmount: Scalars['String'];
};

/** Autogenerated input type of CreateCustomerWalletTransaction */
export type CreateCustomerWalletTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  grantedCredits: Scalars['String'];
  paidCredits: Scalars['String'];
  walletId: Scalars['ID'];
};

/** Autogenerated input type of CreateInvite */
export type CreateInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

/** Autogenerated input type of CreatePlan */
export type CreatePlanInput = {
  amountCents: Scalars['Int'];
  amountCurrency: CurrencyEnum;
  billChargesMonthly?: InputMaybe<Scalars['Boolean']>;
  charges: Array<ChargeInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  interval: PlanInterval;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['ID']>;
  payInAdvance: Scalars['Boolean'];
  trialPeriod?: InputMaybe<Scalars['Float']>;
};

/** Autogenerated input type of CreateSubscription */
export type CreateSubscriptionInput = {
  billingTime: BillingTimeEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  planId: Scalars['ID'];
  subscriptionDate?: InputMaybe<Scalars['ISO8601Date']>;
  subscriptionId?: InputMaybe<Scalars['ID']>;
};

export type CreditNote = {
  __typename?: 'CreditNote';
  balanceAmountCents: Scalars['BigInt'];
  balanceAmountCurrency: CurrencyEnum;
  /** Check if credit note can be voided */
  canBeVoided: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  creditAmountCents: Scalars['BigInt'];
  creditAmountCurrency: CurrencyEnum;
  creditStatus?: Maybe<CreditNoteCreditStatusEnum>;
  description?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoice?: Maybe<Invoice>;
  items: Array<CreditNoteItem>;
  number: Scalars['String'];
  reason: CreditNoteReasonEnum;
  refundAmountCents: Scalars['BigInt'];
  refundAmountCurrency: CurrencyEnum;
  refundStatus?: Maybe<CreditNoteRefundStatusEnum>;
  sequentialId: Scalars['ID'];
  subTotalVatExcludedAmountCents: Scalars['BigInt'];
  subTotalVatExcludedAmountCurrency: CurrencyEnum;
  totalAmountCents: Scalars['BigInt'];
  totalAmountCurrency: CurrencyEnum;
  updatedAt: Scalars['ISO8601DateTime'];
  vatAmountCents: Scalars['BigInt'];
  vatAmountCurrency: CurrencyEnum;
};

export enum CreditNoteCreditStatusEnum {
  Available = 'available',
  Consumed = 'consumed',
  Voided = 'voided'
}

export type CreditNoteItem = {
  __typename?: 'CreditNoteItem';
  createdAt: Scalars['ISO8601DateTime'];
  creditAmountCents: Scalars['BigInt'];
  creditAmountCurrency: CurrencyEnum;
  fee: Fee;
  id: Scalars['ID'];
  refundAmountCents: Scalars['BigInt'];
  refundAmountCurrency: CurrencyEnum;
};

export type CreditNoteItemInput = {
  creditAmountCents: Scalars['BigInt'];
  feeId: Scalars['ID'];
  refundAmountCents: Scalars['BigInt'];
};

export enum CreditNoteReasonEnum {
  DuplicatedCharge = 'duplicated_charge',
  FraudulentCharge = 'fraudulent_charge',
  OrderCancellation = 'order_cancellation',
  OrderChange = 'order_change',
  Other = 'other',
  ProductUnsatisfactory = 'product_unsatisfactory'
}

export enum CreditNoteRefundStatusEnum {
  Failed = 'failed',
  Pending = 'pending',
  Succeeded = 'succeeded'
}

export enum CurrencyEnum {
  /** United Arab Emirates dirham */
  Aed = 'AED',
  /** Afghan afghani */
  Afn = 'AFN',
  /** Albanian lek */
  All = 'ALL',
  /** Armenian dram */
  Amd = 'AMD',
  /** Netherlands Antillean guilder */
  Ang = 'ANG',
  /** Angolan kwanza */
  Aoa = 'AOA',
  /** Argentine peso */
  Ars = 'ARS',
  /** Australian dollar */
  Aud = 'AUD',
  /** Aruban florin */
  Awg = 'AWG',
  /** Azerbaijani manat */
  Azn = 'AZN',
  /** Bosnia and Herzegovina convertible mark */
  Bam = 'BAM',
  /** Barbados dollar */
  Bbd = 'BBD',
  /** Bangladeshi taka */
  Bdt = 'BDT',
  /** Bulgarian lev */
  Bgn = 'BGN',
  /** Bahraini dinar */
  Bif = 'BIF',
  /** Burundian franc */
  Bmd = 'BMD',
  /** Bermudian dollar */
  Bnd = 'BND',
  /** Brunei dollar */
  Bob = 'BOB',
  /** Boliviano */
  Brl = 'BRL',
  /** Brazilian real */
  Bsd = 'BSD',
  /** Bahamian dollar */
  Bwp = 'BWP',
  /** Botswana pula */
  Byn = 'BYN',
  /** Belarusian ruble */
  Bzd = 'BZD',
  /** Belize dollar */
  Cad = 'CAD',
  /** Canadian dollar */
  Cdf = 'CDF',
  /** Congolese franc */
  Chf = 'CHF',
  /** Swiss franc */
  Clp = 'CLP',
  /** Chilean peso */
  Cny = 'CNY',
  /** Colombian peso */
  Cop = 'COP',
  /** Costa Rican colon */
  Crc = 'CRC',
  /** Cape Verdean escudo */
  Cve = 'CVE',
  /** Czech koruna */
  Czk = 'CZK',
  /** Djiboutian franc */
  Djf = 'DJF',
  /** Danish krone */
  Dkk = 'DKK',
  /** Dominican peso */
  Dop = 'DOP',
  /** Algerian dinar */
  Dzd = 'DZD',
  /** Egyptian pound */
  Egp = 'EGP',
  /** Ethiopian birr */
  Etb = 'ETB',
  /** Euro */
  Eur = 'EUR',
  /** Fiji dollar */
  Fjd = 'FJD',
  /** Falkland Islands pound */
  Fkp = 'FKP',
  /** Pound sterling */
  Gbp = 'GBP',
  /** Georgian lari */
  Gel = 'GEL',
  /** Gibraltar pound */
  Gip = 'GIP',
  /** Gambian dalasi */
  Gmd = 'GMD',
  /** Guinean franc */
  Gnf = 'GNF',
  /** Guatemalan quetzal */
  Gtq = 'GTQ',
  /** Guyanese dollar */
  Gyd = 'GYD',
  /** Hong Kong dollar */
  Hkd = 'HKD',
  /** Honduran lempira */
  Hnl = 'HNL',
  /** Croatian kuna */
  Hrk = 'HRK',
  /** Haitian gourde */
  Htg = 'HTG',
  /** Hungarian forint */
  Huf = 'HUF',
  /** Indonesian Rupiah */
  Idr = 'IDR',
  /** Israeli new shekel */
  Ils = 'ILS',
  /** Indian rupee */
  Inr = 'INR',
  /** Icelandic króna */
  Isk = 'ISK',
  /** Jamaican dollar */
  Jmd = 'JMD',
  /** Japanese yen */
  Jpy = 'JPY',
  /** Kenyan shilling */
  Kes = 'KES',
  /** Kyrgyzstani som */
  Kgs = 'KGS',
  /** Cambodian riel */
  Khr = 'KHR',
  /** Comoro franc */
  Kmf = 'KMF',
  /** South Korean won */
  Krw = 'KRW',
  /** Cayman Islands dollar */
  Kyd = 'KYD',
  /** Kazakhstani tenge */
  Kzt = 'KZT',
  /** Lao kip */
  Lak = 'LAK',
  /** Lebanese pound */
  Lbp = 'LBP',
  /** Sri Lankan rupee */
  Lkr = 'LKR',
  /** Liberian dollar */
  Lrd = 'LRD',
  /** Lesotho loti */
  Lsl = 'LSL',
  /** Moroccan dirham */
  Mad = 'MAD',
  /** Moldovan leu */
  Mdl = 'MDL',
  /** Malagasy ariary */
  Mga = 'MGA',
  /** Macedonian denar */
  Mkd = 'MKD',
  /** Myanmar kyat */
  Mmk = 'MMK',
  /** Mongolian tögrög */
  Mnt = 'MNT',
  /** Macanese pataca */
  Mop = 'MOP',
  /** Mauritanian ouguiya */
  Mro = 'MRO',
  /** Mauritian rupee */
  Mur = 'MUR',
  /** Maldivian rufiyaa */
  Mvr = 'MVR',
  /** Malawian kwacha */
  Mwk = 'MWK',
  /** Mexican peso */
  Mxn = 'MXN',
  /** Malaysian ringgit */
  Myr = 'MYR',
  /** Mozambican metical */
  Mzn = 'MZN',
  /** Namibian dollar */
  Nad = 'NAD',
  /** Nigerian naira */
  Ngn = 'NGN',
  /** Nicaraguan córdoba */
  Nio = 'NIO',
  /** Norwegian Krone */
  Nok = 'NOK',
  /** Nepalese rupee */
  Npr = 'NPR',
  /** New Zealand dollar */
  Nzd = 'NZD',
  /** Panamanian balboa */
  Pab = 'PAB',
  /** Peruvian sol */
  Pen = 'PEN',
  /** Papua New Guinean kina */
  Pgk = 'PGK',
  /** Philippine peso */
  Php = 'PHP',
  /** Pakistani rupee */
  Pkr = 'PKR',
  /** Polish złoty */
  Pln = 'PLN',
  /** Paraguayan guaraní */
  Pyg = 'PYG',
  /** Qatari riyal */
  Qar = 'QAR',
  /** Romanian leu */
  Ron = 'RON',
  /** Serbian dinar */
  Rsd = 'RSD',
  /** Russian ruble */
  Rub = 'RUB',
  /** Rwandan franc */
  Rwf = 'RWF',
  /** Saudi riyal */
  Sar = 'SAR',
  /** Solomon Islands dollar */
  Sbd = 'SBD',
  /** Seychelles rupee */
  Scr = 'SCR',
  /** Swedish Krona */
  Sek = 'SEK',
  /** Singapore dollar */
  Sgd = 'SGD',
  /** Saint Helena pound */
  Shp = 'SHP',
  /** Sierra Leonean leone */
  Sll = 'SLL',
  /** Somali shilling */
  Sos = 'SOS',
  /** Surinamese dollar */
  Srd = 'SRD',
  /** São Tomé and Príncipe dobra */
  Std = 'STD',
  /** Swazi lilangeni */
  Szl = 'SZL',
  /** Thai baht */
  Thb = 'THB',
  /** Tajikistani somoni */
  Tjs = 'TJS',
  /** Tongan paʻanga */
  Top = 'TOP',
  /** Turkish lira */
  Try = 'TRY',
  /** Trinidad and Tobago dollar */
  Ttd = 'TTD',
  /** New Taiwan dollar */
  Twd = 'TWD',
  /** Tanzanian shilling */
  Tzs = 'TZS',
  /** Ukrainian hryvnia */
  Uah = 'UAH',
  /** Ugandan shilling */
  Ugx = 'UGX',
  /** American Dollar */
  Usd = 'USD',
  /** Uruguayan peso */
  Uyu = 'UYU',
  /** Uzbekistan som */
  Uzs = 'UZS',
  /** Vietnamese đồng */
  Vnd = 'VND',
  /** Vanuatu vatu */
  Vuv = 'VUV',
  /** Samoan tala */
  Wst = 'WST',
  /** CFA franc BEAC */
  Xaf = 'XAF',
  /** East Caribbean dollar */
  Xcd = 'XCD',
  /** CFA franc BCEAO */
  Xof = 'XOF',
  /** CFP franc (franc Pacifique) */
  Xpf = 'XPF',
  /** Yemeni rial */
  Yer = 'YER',
  /** South African rand */
  Zar = 'ZAR',
  /** Zambian kwacha */
  Zmw = 'ZMW'
}

export type CurrentVersion = {
  __typename?: 'CurrentVersion';
  githubUrl: Scalars['String'];
  number: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  /** Number of active subscriptions per customer */
  activeSubscriptionCount: Scalars['Int'];
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  /** Check if customer is deletable */
  canBeDeleted: Scalars['Boolean'];
  /** Check if customer attributes are editable */
  canEditAttributes: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
  createdAt: Scalars['ISO8601DateTime'];
  currency?: Maybe<CurrencyEnum>;
  email?: Maybe<Scalars['String']>;
  externalId: Scalars['String'];
  /** Define if a customer has an active wallet */
  hasActiveWallet: Scalars['Boolean'];
  id: Scalars['ID'];
  legalName?: Maybe<Scalars['String']>;
  legalNumber?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentProvider?: Maybe<ProviderTypeEnum>;
  phone?: Maybe<Scalars['String']>;
  sequentialId: Scalars['String'];
  slug: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomer>;
  subscriptions?: Maybe<Array<Subscription>>;
  updatedAt: Scalars['ISO8601DateTime'];
  url?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type CustomerCollection = {
  __typename?: 'CustomerCollection';
  collection: Array<Customer>;
  metadata: CollectionMetadata;
};

export type CustomerDetails = {
  __typename?: 'CustomerDetails';
  /** Number of active subscriptions per customer */
  activeSubscriptionCount: Scalars['Int'];
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  appliedAddOns?: Maybe<Array<AppliedAddOn>>;
  appliedCoupons?: Maybe<Array<AppliedCoupon>>;
  /** Check if customer is deletable */
  canBeDeleted: Scalars['Boolean'];
  /** Check if customer attributes are editable */
  canEditAttributes: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
  createdAt: Scalars['ISO8601DateTime'];
  creditNotes?: Maybe<Array<CreditNote>>;
  currency?: Maybe<CurrencyEnum>;
  email?: Maybe<Scalars['String']>;
  externalId: Scalars['String'];
  /** Define if a customer has an active wallet */
  hasActiveWallet: Scalars['Boolean'];
  id: Scalars['ID'];
  invoices?: Maybe<Array<Invoice>>;
  legalName?: Maybe<Scalars['String']>;
  legalNumber?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentProvider?: Maybe<ProviderTypeEnum>;
  phone?: Maybe<Scalars['String']>;
  sequentialId: Scalars['String'];
  slug: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomer>;
  /** Query subscriptions of a customer */
  subscriptions: Array<Subscription>;
  updatedAt: Scalars['ISO8601DateTime'];
  url?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
  zipcode?: Maybe<Scalars['String']>;
};


export type CustomerDetailsSubscriptionsArgs = {
  status?: InputMaybe<Array<StatusTypeEnum>>;
};

export type CustomerUsage = {
  __typename?: 'CustomerUsage';
  amountCents: Scalars['BigInt'];
  amountCurrency: CurrencyEnum;
  chargesUsage: Array<ChargeUsage>;
  fromDate: Scalars['ISO8601Date'];
  issuingDate: Scalars['ISO8601Date'];
  toDate: Scalars['ISO8601Date'];
  totalAmountCents: Scalars['BigInt'];
  totalAmountCurrency: CurrencyEnum;
  vatAmountCents: Scalars['BigInt'];
  vatAmountCurrency: CurrencyEnum;
};

/** Autogenerated input type of DestroyAddOn */
export type DestroyAddOnInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyAddOn */
export type DestroyAddOnPayload = {
  __typename?: 'DestroyAddOnPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of DestroyBillableMetric */
export type DestroyBillableMetricInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

/** Autogenerated return type of DestroyBillableMetric */
export type DestroyBillableMetricPayload = {
  __typename?: 'DestroyBillableMetricPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of DestroyCoupon */
export type DestroyCouponInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyCoupon */
export type DestroyCouponPayload = {
  __typename?: 'DestroyCouponPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of DestroyCustomer */
export type DestroyCustomerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyCustomer */
export type DestroyCustomerPayload = {
  __typename?: 'DestroyCustomerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of DestroyPaymentProvider */
export type DestroyPaymentProviderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyPaymentProvider */
export type DestroyPaymentProviderPayload = {
  __typename?: 'DestroyPaymentProviderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of DestroyPlan */
export type DestroyPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyPlan */
export type DestroyPlanPayload = {
  __typename?: 'DestroyPlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of DownloadCreditNote */
export type DownloadCreditNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DownloadInvoice */
export type DownloadInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type Event = {
  __typename?: 'Event';
  apiClient?: Maybe<Scalars['String']>;
  billableMetricName?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  externalCustomerId: Scalars['String'];
  externalSubscriptionId: Scalars['String'];
  id: Scalars['ID'];
  ipAddress?: Maybe<Scalars['String']>;
  matchBillableMetric: Scalars['Boolean'];
  matchCustomField: Scalars['Boolean'];
  payload: Scalars['JSON'];
  receivedAt: Scalars['ISO8601DateTime'];
  timestamp?: Maybe<Scalars['ISO8601DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type EventCollection = {
  __typename?: 'EventCollection';
  collection: Array<Event>;
  metadata: CollectionMetadata;
};

export type Fee = InvoiceItem & {
  __typename?: 'Fee';
  amountCents: Scalars['BigInt'];
  amountCurrency: CurrencyEnum;
  charge?: Maybe<Charge>;
  eventsCount?: Maybe<Scalars['BigInt']>;
  feeType: FeeTypesEnum;
  group?: Maybe<Group>;
  id: Scalars['ID'];
  itemCode: Scalars['String'];
  itemName: Scalars['String'];
  itemType: Scalars['String'];
  subscription?: Maybe<Subscription>;
  units: Scalars['Float'];
  vatAmountCents: Scalars['BigInt'];
  vatAmountCurrency: CurrencyEnum;
  vatRate?: Maybe<Scalars['Float']>;
};

export enum FeeTypesEnum {
  AddOn = 'add_on',
  Charge = 'charge',
  Credit = 'credit',
  Subscription = 'subscription'
}

export type GocardlessProvider = {
  __typename?: 'GocardlessProvider';
  hasAccessToken: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type GraduatedRange = {
  __typename?: 'GraduatedRange';
  flatAmount: Scalars['String'];
  fromValue: Scalars['Int'];
  perUnitAmount: Scalars['String'];
  toValue?: Maybe<Scalars['Int']>;
};

export type GraduatedRangeInput = {
  flatAmount: Scalars['String'];
  fromValue: Scalars['Int'];
  perUnitAmount: Scalars['String'];
  toValue?: InputMaybe<Scalars['Int']>;
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type GroupProperties = {
  __typename?: 'GroupProperties';
  groupId: Scalars['ID'];
  values: Properties;
};

export type GroupPropertiesInput = {
  groupId: Scalars['ID'];
  values: PropertiesInput;
};

export type GroupUsage = {
  __typename?: 'GroupUsage';
  amountCents: Scalars['BigInt'];
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  units: Scalars['Float'];
  value: Scalars['String'];
};

export type Invite = {
  __typename?: 'Invite';
  acceptedAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  organization: Organization;
  recipient: Membership;
  revokedAt: Scalars['ISO8601DateTime'];
  status: InviteStatusTypeEnum;
  token: Scalars['String'];
};

export type InviteCollection = {
  __typename?: 'InviteCollection';
  collection: Array<Invite>;
  metadata: CollectionMetadata;
};

export enum InviteStatusTypeEnum {
  Accepted = 'accepted',
  Pending = 'pending',
  Revoked = 'revoked'
}

export type Invoice = {
  __typename?: 'Invoice';
  amountCents: Scalars['Int'];
  amountCurrency: CurrencyEnum;
  chargeAmountCents: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  creditAmountCents: Scalars['Int'];
  creditAmountCurrency: CurrencyEnum;
  customer: Customer;
  fees?: Maybe<Array<Fee>>;
  fileUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoiceSubscriptions?: Maybe<Array<InvoiceSubscription>>;
  invoiceType: InvoiceTypeEnum;
  issuingDate: Scalars['ISO8601Date'];
  number: Scalars['String'];
  plan?: Maybe<Plan>;
  sequentialId: Scalars['ID'];
  status: InvoiceStatusTypeEnum;
  subscriptions?: Maybe<Array<Subscription>>;
  subtotalBeforePrepaidCredits: Scalars['String'];
  totalAmountCents: Scalars['Int'];
  totalAmountCurrency: CurrencyEnum;
  updatedAt: Scalars['ISO8601DateTime'];
  vatAmountCents: Scalars['Int'];
  vatAmountCurrency: CurrencyEnum;
  walletTransactionAmountCents: Scalars['Int'];
};

/** Invoice Item */
export type InvoiceItem = {
  amountCents: Scalars['BigInt'];
  amountCurrency: CurrencyEnum;
  group?: Maybe<Group>;
  id: Scalars['ID'];
  itemCode: Scalars['String'];
  itemName: Scalars['String'];
  itemType: Scalars['String'];
};

export enum InvoiceStatusTypeEnum {
  Failed = 'failed',
  Pending = 'pending',
  Succeeded = 'succeeded'
}

export type InvoiceSubscription = {
  __typename?: 'InvoiceSubscription';
  chargeAmountCents: Scalars['Int'];
  fees?: Maybe<Array<Fee>>;
  fromDate: Scalars['ISO8601Date'];
  invoice: Invoice;
  subscription: Subscription;
  subscriptionAmountCents: Scalars['Int'];
  toDate: Scalars['ISO8601Date'];
  totalAmountCents: Scalars['Int'];
};

export enum InvoiceTypeEnum {
  AddOn = 'add_on',
  Credit = 'credit',
  Subscription = 'subscription'
}

export enum LagoApiError {
  CouponAlreadyApplied = 'coupon_already_applied',
  CurrenciesDoesNotMatch = 'currencies_does_not_match',
  EmailAlreadyUsed = 'email_already_used',
  ExpiredJwtToken = 'expired_jwt_token',
  Forbidden = 'forbidden',
  IncorrectLoginOrPassword = 'incorrect_login_or_password',
  InternalError = 'internal_error',
  InviteAlreadyExists = 'invite_already_exists',
  InviteNotFound = 'invite_not_found',
  NotFound = 'not_found',
  NotOrganizationMember = 'not_organization_member',
  PlanNotFound = 'plan_not_found',
  TokenEncodingError = 'token_encoding_error',
  Unauthorized = 'unauthorized',
  UnprocessableEntity = 'unprocessable_entity',
  UrlIsInvalid = 'url_is_invalid',
  UserAlreadyExists = 'user_already_exists',
  ValueAlreadyExist = 'value_already_exist',
  ValueIsInvalid = 'value_is_invalid',
  ValueIsOutOfRange = 'value_is_out_of_range'
}

export type LoginUser = {
  __typename?: 'LoginUser';
  token: Scalars['String'];
  user: User;
};

/** Autogenerated input type of LoginUser */
export type LoginUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Membership = {
  __typename?: 'Membership';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  organization: Organization;
  revokedAt: Scalars['ISO8601DateTime'];
  role?: Maybe<Scalars['String']>;
  status: MembershipStatus;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

export type MembershipCollection = {
  __typename?: 'MembershipCollection';
  collection: Array<Membership>;
  metadata: CollectionMetadata;
};

export enum MembershipStatus {
  Active = 'active',
  Revoked = 'revoked'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Accepts a new Invite */
  acceptInvite?: Maybe<RegisterUser>;
  /** Add or update Gocardless payment provider */
  addGocardlessPaymentProvider?: Maybe<GocardlessProvider>;
  /** Add or update Stripe API keys to the organization */
  addStripePaymentProvider?: Maybe<StripeProvider>;
  /** Creates a new add-on */
  createAddOn?: Maybe<AddOn>;
  /** Assigns an add-on to a Customer */
  createAppliedAddOn?: Maybe<AppliedAddOn>;
  /** Assigns a Coupon to a Customer */
  createAppliedCoupon?: Maybe<AppliedCoupon>;
  /** Creates a new Billable metric */
  createBillableMetric?: Maybe<BillableMetric>;
  /** Creates a new Coupon */
  createCoupon?: Maybe<Coupon>;
  /** Creates a new Credit Note */
  createCreditNote?: Maybe<CreditNote>;
  /** Creates a new customer */
  createCustomer?: Maybe<Customer>;
  /** Creates a new Customer Wallet */
  createCustomerWallet?: Maybe<Wallet>;
  /** Creates a new Customer Wallet Transaction */
  createCustomerWalletTransaction?: Maybe<WalletTransactionCollection>;
  /** Creates a new Invite */
  createInvite?: Maybe<Invite>;
  /** Creates a new Plan */
  createPlan?: Maybe<Plan>;
  /** Create a new Subscription */
  createSubscription?: Maybe<Subscription>;
  /** Deletes an add-on */
  destroyAddOn?: Maybe<DestroyAddOnPayload>;
  /** Deletes a Billable metric */
  destroyBillableMetric?: Maybe<DestroyBillableMetricPayload>;
  /** Deletes a coupon */
  destroyCoupon?: Maybe<DestroyCouponPayload>;
  /** Delete a Customer */
  destroyCustomer?: Maybe<DestroyCustomerPayload>;
  /** Destroy a payment provider */
  destroyPaymentProvider?: Maybe<DestroyPaymentProviderPayload>;
  /** Deletes a Plan */
  destroyPlan?: Maybe<DestroyPlanPayload>;
  /** Download a Credit Note PDF */
  downloadCreditNote?: Maybe<CreditNote>;
  /** Download an Invoice PDF */
  downloadInvoice?: Maybe<Invoice>;
  /** Opens a session for an existing user */
  loginUser?: Maybe<LoginUser>;
  /** Registers a new user and creates related organization */
  registerUser?: Maybe<RegisterUser>;
  /** Revokes a invite */
  revokeInvite?: Maybe<Invite>;
  /** Revoke a membership */
  revokeMembership?: Maybe<Membership>;
  /** Unassign a coupon from a customer */
  terminateAppliedCoupon?: Maybe<AppliedCoupon>;
  /** Deletes a coupon */
  terminateCoupon?: Maybe<Coupon>;
  /** Terminates a new Customer Wallet */
  terminateCustomerWallet?: Maybe<Wallet>;
  /** Terminate a Subscription */
  terminateSubscription?: Maybe<Subscription>;
  /** Update an existing add-on */
  updateAddOn?: Maybe<AddOn>;
  /** Updates an existing Billable metric */
  updateBillableMetric?: Maybe<BillableMetric>;
  /** Update an existing coupon */
  updateCoupon?: Maybe<Coupon>;
  /** Updates an existing Credit Note */
  updateCreditNote?: Maybe<CreditNote>;
  /** Updates an existing Customer */
  updateCustomer?: Maybe<Customer>;
  /** Assign the vat rate to Customers */
  updateCustomerVatRate?: Maybe<CustomerDetails>;
  /** Updates a new Customer Wallet */
  updateCustomerWallet?: Maybe<Wallet>;
  /** Updates an Organization */
  updateOrganization?: Maybe<Organization>;
  /** Updates an existing Plan */
  updatePlan?: Maybe<Plan>;
  /** Update a Subscription */
  updateSubscription?: Maybe<Subscription>;
  /** Voids a Credit Note */
  voidCreditNote?: Maybe<CreditNote>;
};


export type MutationAcceptInviteArgs = {
  input: AcceptInviteInput;
};


export type MutationAddGocardlessPaymentProviderArgs = {
  input: AddGocardlessPaymentProviderInput;
};


export type MutationAddStripePaymentProviderArgs = {
  input: AddStripePaymentProviderInput;
};


export type MutationCreateAddOnArgs = {
  input: CreateAddOnInput;
};


export type MutationCreateAppliedAddOnArgs = {
  input: CreateAppliedAddOnInput;
};


export type MutationCreateAppliedCouponArgs = {
  input: CreateAppliedCouponInput;
};


export type MutationCreateBillableMetricArgs = {
  input: CreateBillableMetricInput;
};


export type MutationCreateCouponArgs = {
  input: CreateCouponInput;
};


export type MutationCreateCreditNoteArgs = {
  input: CreateCreditNoteInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationCreateCustomerWalletArgs = {
  input: CreateCustomerWalletInput;
};


export type MutationCreateCustomerWalletTransactionArgs = {
  input: CreateCustomerWalletTransactionInput;
};


export type MutationCreateInviteArgs = {
  input: CreateInviteInput;
};


export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MutationDestroyAddOnArgs = {
  input: DestroyAddOnInput;
};


export type MutationDestroyBillableMetricArgs = {
  input: DestroyBillableMetricInput;
};


export type MutationDestroyCouponArgs = {
  input: DestroyCouponInput;
};


export type MutationDestroyCustomerArgs = {
  input: DestroyCustomerInput;
};


export type MutationDestroyPaymentProviderArgs = {
  input: DestroyPaymentProviderInput;
};


export type MutationDestroyPlanArgs = {
  input: DestroyPlanInput;
};


export type MutationDownloadCreditNoteArgs = {
  input: DownloadCreditNoteInput;
};


export type MutationDownloadInvoiceArgs = {
  input: DownloadInvoiceInput;
};


export type MutationLoginUserArgs = {
  input: LoginUserInput;
};


export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


export type MutationRevokeInviteArgs = {
  input: RevokeInviteInput;
};


export type MutationRevokeMembershipArgs = {
  input: RevokeMembershipInput;
};


export type MutationTerminateAppliedCouponArgs = {
  input: TerminateAppliedCouponInput;
};


export type MutationTerminateCouponArgs = {
  input: TerminateCouponInput;
};


export type MutationTerminateCustomerWalletArgs = {
  input: TerminateCustomerWalletInput;
};


export type MutationTerminateSubscriptionArgs = {
  input: TerminateSubscriptionInput;
};


export type MutationUpdateAddOnArgs = {
  input: UpdateAddOnInput;
};


export type MutationUpdateBillableMetricArgs = {
  input: UpdateBillableMetricInput;
};


export type MutationUpdateCouponArgs = {
  input: UpdateCouponInput;
};


export type MutationUpdateCreditNoteArgs = {
  input: UpdateCreditNoteInput;
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationUpdateCustomerVatRateArgs = {
  input: UpdateCustomerVatRateInput;
};


export type MutationUpdateCustomerWalletArgs = {
  input: UpdateCustomerWalletInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationUpdatePlanArgs = {
  input: UpdatePlanInput;
};


export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};


export type MutationVoidCreditNoteArgs = {
  input: VoidCreditNoteInput;
};

export type Organization = {
  __typename?: 'Organization';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  apiKey: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
  createdAt: Scalars['ISO8601DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoiceFooter?: Maybe<Scalars['String']>;
  legalName?: Maybe<Scalars['String']>;
  legalNumber?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  stripePaymentProvider?: Maybe<StripeProvider>;
  updatedAt: Scalars['ISO8601DateTime'];
  vatRate: Scalars['Float'];
  webhookUrl?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type Plan = {
  __typename?: 'Plan';
  amountCents: Scalars['Int'];
  amountCurrency: CurrencyEnum;
  billChargesMonthly?: Maybe<Scalars['Boolean']>;
  /** Check if plan is deletable */
  canBeDeleted: Scalars['Boolean'];
  /** Number of charges attached to a plan */
  chargeCount: Scalars['Int'];
  charges?: Maybe<Array<Charge>>;
  code: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  /** Number of customers attached to a plan */
  customerCount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  interval: PlanInterval;
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  parentId?: Maybe<Scalars['ID']>;
  payInAdvance: Scalars['Boolean'];
  trialPeriod?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type PlanCollection = {
  __typename?: 'PlanCollection';
  collection: Array<Plan>;
  metadata: CollectionMetadata;
};

export type PlanDetails = {
  __typename?: 'PlanDetails';
  amountCents: Scalars['Int'];
  amountCurrency: CurrencyEnum;
  billChargesMonthly?: Maybe<Scalars['Boolean']>;
  /** Check if plan is deletable */
  canBeDeleted: Scalars['Boolean'];
  /** Number of charges attached to a plan */
  chargeCount: Scalars['Int'];
  charges?: Maybe<Array<Charge>>;
  code: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  /** Number of customers attached to a plan */
  customerCount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  interval: PlanInterval;
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  parentId?: Maybe<Scalars['ID']>;
  payInAdvance: Scalars['Boolean'];
  trialPeriod?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum PlanInterval {
  Monthly = 'monthly',
  Weekly = 'weekly',
  Yearly = 'yearly'
}

export type Properties = {
  __typename?: 'Properties';
  amount?: Maybe<Scalars['String']>;
  fixedAmount?: Maybe<Scalars['String']>;
  freeUnits?: Maybe<Scalars['Int']>;
  freeUnitsPerEvents?: Maybe<Scalars['Int']>;
  freeUnitsPerTotalAggregation?: Maybe<Scalars['String']>;
  graduatedRanges?: Maybe<Array<GraduatedRange>>;
  packageSize?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['String']>;
  volumeRanges?: Maybe<Array<VolumeRange>>;
};

export type PropertiesInput = {
  amount?: InputMaybe<Scalars['String']>;
  fixedAmount?: InputMaybe<Scalars['String']>;
  freeUnits?: InputMaybe<Scalars['Int']>;
  freeUnitsPerEvents?: InputMaybe<Scalars['Int']>;
  freeUnitsPerTotalAggregation?: InputMaybe<Scalars['String']>;
  graduatedRanges?: InputMaybe<Array<GraduatedRangeInput>>;
  packageSize?: InputMaybe<Scalars['Int']>;
  rate?: InputMaybe<Scalars['String']>;
  volumeRanges?: InputMaybe<Array<VolumeRangeInput>>;
};

export enum ProviderTypeEnum {
  Stripe = 'stripe'
}

export type Query = {
  __typename?: 'Query';
  /** Query a single add-on of an organization */
  addOn?: Maybe<AddOnDetails>;
  /** Query add-ons of an organization */
  addOns: AddOnCollection;
  /** Query a single billable metric of an organization */
  billableMetric?: Maybe<BillableMetricDetail>;
  /** Query billable metrics of an organization */
  billableMetrics: BillableMetricCollection;
  /** Query a single coupon of an organization */
  coupon?: Maybe<CouponDetails>;
  /** Query coupons of an organization */
  coupons: CouponCollection;
  /** Query a single credit note */
  creditNote?: Maybe<CreditNote>;
  /** Retrieves currently connected user */
  currentUser: User;
  /** Retrieve the version of the application */
  currentVersion: CurrentVersion;
  /** Query a single customer of an organization */
  customer?: Maybe<CustomerDetails>;
  /** Query the usage of the customer on the current billing period */
  customerUsage: CustomerUsage;
  /** Query customers of an organization */
  customers: CustomerCollection;
  /** Query events of an organization */
  events?: Maybe<EventCollection>;
  /** Query a single Invite */
  invite?: Maybe<Invite>;
  /** Query pending invites of an organization */
  invites: InviteCollection;
  /** Query a single Invoice of an organization */
  invoice?: Maybe<Invoice>;
  /** Query memberships of an organization */
  memberships: MembershipCollection;
  /** Query a single plan of an organization */
  plan?: Maybe<PlanDetails>;
  /** Query plans of an organization */
  plans: PlanCollection;
  token: Scalars['Boolean'];
  /** Query a single wallet */
  wallet?: Maybe<WalletDetails>;
  /** Query a single wallet transaction */
  walletTransaction?: Maybe<WalletTransactionDetails>;
  /** Query wallet transactions */
  walletTransactions: WalletTransactionCollection;
  /** Query wallets */
  wallets: WalletCollection;
};


export type QueryAddOnArgs = {
  id: Scalars['ID'];
};


export type QueryAddOnsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryBillableMetricArgs = {
  id: Scalars['ID'];
};


export type QueryBillableMetricsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryCouponArgs = {
  id: Scalars['ID'];
};


export type QueryCouponsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<CouponStatusEnum>;
};


export type QueryCreditNoteArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerUsageArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
  subscriptionId: Scalars['ID'];
};


export type QueryCustomersArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryInviteArgs = {
  token: Scalars['String'];
};


export type QueryInvitesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryMembershipsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPlanArgs = {
  id: Scalars['ID'];
};


export type QueryPlansArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryWalletArgs = {
  id: Scalars['ID'];
};


export type QueryWalletTransactionArgs = {
  id: Scalars['ID'];
};


export type QueryWalletTransactionsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<WalletTransactionStatusEnum>;
  transactionType?: InputMaybe<WalletTransactionTransactionTypeEnum>;
  walletId: Scalars['ID'];
};


export type QueryWalletsArgs = {
  customerId: Scalars['ID'];
  ids?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<WalletStatusEnum>;
};

export type RegisterUser = {
  __typename?: 'RegisterUser';
  membership: Membership;
  organization: Organization;
  token: Scalars['String'];
  user: User;
};

/** Autogenerated input type of RegisterUser */
export type RegisterUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  organizationName: Scalars['String'];
  password: Scalars['String'];
};

/** Autogenerated input type of RevokeInvite */
export type RevokeInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of RevokeMembership */
export type RevokeMembershipInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export enum StatusTypeEnum {
  Active = 'active',
  Canceled = 'canceled',
  Pending = 'pending',
  Terminated = 'terminated'
}

export type StripeCustomer = {
  __typename?: 'StripeCustomer';
  id: Scalars['ID'];
  providerCustomerId?: Maybe<Scalars['ID']>;
};

export type StripeCustomerInput = {
  providerCustomerId?: InputMaybe<Scalars['ID']>;
};

export type StripeProvider = {
  __typename?: 'StripeProvider';
  createCustomers: Scalars['Boolean'];
  id: Scalars['ID'];
  secretKey: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  billingTime?: Maybe<BillingTimeEnum>;
  canceledAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  externalId: Scalars['String'];
  fees?: Maybe<Array<Fee>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nextName?: Maybe<Scalars['String']>;
  nextPendingStartDate?: Maybe<Scalars['ISO8601Date']>;
  nextPlan?: Maybe<Plan>;
  periodEndDate?: Maybe<Scalars['ISO8601Date']>;
  plan: Plan;
  startedAt?: Maybe<Scalars['ISO8601DateTime']>;
  status?: Maybe<StatusTypeEnum>;
  subscriptionDate?: Maybe<Scalars['ISO8601Date']>;
  terminatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of TerminateAppliedCoupon */
export type TerminateAppliedCouponInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of TerminateCoupon */
export type TerminateCouponInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of TerminateCustomerWallet */
export type TerminateCustomerWalletInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of TerminateSubscription */
export type TerminateSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateAddOn */
export type UpdateAddOnInput = {
  amountCents: Scalars['Int'];
  amountCurrency: CurrencyEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated input type of UpdateBillableMetric */
export type UpdateBillableMetricInput = {
  aggregationType: AggregationTypeEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  fieldName?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['JSON']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

/** Autogenerated input type of UpdateCoupon */
export type UpdateCouponInput = {
  amountCents?: InputMaybe<Scalars['Int']>;
  amountCurrency?: InputMaybe<CurrencyEnum>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  couponType: CouponTypeEnum;
  expiration: CouponExpiration;
  expirationDate?: InputMaybe<Scalars['ISO8601Date']>;
  frequency: CouponFrequency;
  frequencyDuration?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  name: Scalars['String'];
  percentageRate?: InputMaybe<Scalars['Float']>;
};

/** Autogenerated input type of UpdateCreditNote */
export type UpdateCreditNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  refundStatus: CreditNoteRefundStatusEnum;
};

/** Autogenerated input type of UpdateCustomer */
export type UpdateCustomerInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  currency?: InputMaybe<CurrencyEnum>;
  email?: InputMaybe<Scalars['String']>;
  externalId: Scalars['String'];
  id: Scalars['ID'];
  legalName?: InputMaybe<Scalars['String']>;
  legalNumber?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  paymentProvider?: InputMaybe<ProviderTypeEnum>;
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  stripeCustomer?: InputMaybe<StripeCustomerInput>;
  url?: InputMaybe<Scalars['String']>;
  vatRate?: InputMaybe<Scalars['Float']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateCustomerVatRate */
export type UpdateCustomerVatRateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  vatRate?: InputMaybe<Scalars['Float']>;
};

/** Autogenerated input type of UpdateCustomerWallet */
export type UpdateCustomerWalletInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  expirationDate?: InputMaybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateOrganization */
export type UpdateOrganizationInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  email?: InputMaybe<Scalars['String']>;
  invoiceFooter?: InputMaybe<Scalars['String']>;
  legalName?: InputMaybe<Scalars['String']>;
  legalNumber?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  vatRate?: InputMaybe<Scalars['Float']>;
  webhookUrl?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of UpdatePlan */
export type UpdatePlanInput = {
  amountCents: Scalars['Int'];
  amountCurrency: CurrencyEnum;
  billChargesMonthly?: InputMaybe<Scalars['Boolean']>;
  charges: Array<ChargeInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  interval: PlanInterval;
  name: Scalars['String'];
  payInAdvance: Scalars['Boolean'];
  trialPeriod?: InputMaybe<Scalars['Float']>;
};

/** Autogenerated input type of UpdateSubscription */
export type UpdateSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  subscriptionDate?: InputMaybe<Scalars['ISO8601Date']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['ISO8601DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  organizations?: Maybe<Array<Organization>>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of VoidCreditNote */
export type VoidCreditNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type VolumeRange = {
  __typename?: 'VolumeRange';
  flatAmount: Scalars['String'];
  fromValue: Scalars['Int'];
  perUnitAmount: Scalars['String'];
  toValue?: Maybe<Scalars['Int']>;
};

export type VolumeRangeInput = {
  flatAmount: Scalars['String'];
  fromValue: Scalars['Int'];
  perUnitAmount: Scalars['String'];
  toValue?: InputMaybe<Scalars['Int']>;
};

export type Wallet = {
  __typename?: 'Wallet';
  balance: Scalars['String'];
  consumedAmount: Scalars['String'];
  consumedCredits: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  creditsBalance: Scalars['String'];
  currency: CurrencyEnum;
  customer?: Maybe<Customer>;
  expirationDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  lastBalanceSyncAt?: Maybe<Scalars['ISO8601DateTime']>;
  lastConsumedCreditAt?: Maybe<Scalars['ISO8601DateTime']>;
  name?: Maybe<Scalars['String']>;
  rateAmount: Scalars['String'];
  status: WalletStatusEnum;
  terminatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type WalletCollection = {
  __typename?: 'WalletCollection';
  collection: Array<Wallet>;
  metadata: CollectionMetadata;
};

export type WalletDetails = {
  __typename?: 'WalletDetails';
  balance: Scalars['String'];
  consumedAmount: Scalars['String'];
  consumedCredits: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  creditsBalance: Scalars['String'];
  currency: CurrencyEnum;
  customer?: Maybe<Customer>;
  expirationDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  lastBalanceSyncAt?: Maybe<Scalars['ISO8601DateTime']>;
  lastConsumedCreditAt?: Maybe<Scalars['ISO8601DateTime']>;
  name?: Maybe<Scalars['String']>;
  rateAmount: Scalars['String'];
  status: WalletStatusEnum;
  terminatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum WalletStatusEnum {
  Active = 'active',
  Terminated = 'terminated'
}

export type WalletTransaction = {
  __typename?: 'WalletTransaction';
  amount: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  creditAmount: Scalars['String'];
  id: Scalars['ID'];
  settledAt?: Maybe<Scalars['ISO8601DateTime']>;
  status: WalletTransactionStatusEnum;
  transactionType: WalletTransactionTransactionTypeEnum;
  updatedAt: Scalars['ISO8601DateTime'];
  wallet?: Maybe<Wallet>;
};

export type WalletTransactionCollection = {
  __typename?: 'WalletTransactionCollection';
  collection: Array<WalletTransaction>;
  metadata: CollectionMetadata;
};

export type WalletTransactionDetails = {
  __typename?: 'WalletTransactionDetails';
  amount: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  creditAmount: Scalars['String'];
  id: Scalars['ID'];
  settledAt?: Maybe<Scalars['ISO8601DateTime']>;
  status: WalletTransactionStatusEnum;
  transactionType: WalletTransactionTransactionTypeEnum;
  updatedAt: Scalars['ISO8601DateTime'];
  wallet?: Maybe<Wallet>;
};

export enum WalletTransactionStatusEnum {
  Pending = 'pending',
  Settled = 'settled'
}

export enum WalletTransactionTransactionTypeEnum {
  Inbound = 'inbound',
  Outbound = 'outbound'
}

export type CurrentOrganizationFragment = { __typename?: 'Organization', id: string, name: string, logoUrl?: string | null, apiKey: string, vatRate: number };

export type CurrentUserFragment = { __typename?: 'User', id: string, email?: string | null, organizations?: Array<{ __typename?: 'Organization', id: string, name: string, logoUrl?: string | null, apiKey: string, vatRate: number }> | null };

export type UserIdentifierQueryVariables = Exact<{ [key: string]: never; }>;


export type UserIdentifierQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, email?: string | null, organizations?: Array<{ __typename?: 'Organization', id: string, name: string, logoUrl?: string | null, apiKey: string, vatRate: number }> | null } };

export type AddOnItemFragment = { __typename?: 'AddOn', id: string, name: string, amountCurrency: CurrencyEnum, amountCents: number, customerCount: number, createdAt: any, canBeDeleted: boolean };

export type DeleteAddOnFragment = { __typename?: 'AddOn', id: string, name: string };

export type DeleteAddOnMutationVariables = Exact<{
  input: DestroyAddOnInput;
}>;


export type DeleteAddOnMutation = { __typename?: 'Mutation', destroyAddOn?: { __typename?: 'DestroyAddOnPayload', id?: string | null } | null };

export type BillableMetricItemFragment = { __typename?: 'BillableMetric', id: string, name: string, code: string, createdAt: any, canBeDeleted: boolean };

export type DeleteBillableMetricDialogFragment = { __typename?: 'BillableMetric', id: string, name: string };

export type DeleteBillableMetricMutationVariables = Exact<{
  input: DestroyBillableMetricInput;
}>;


export type DeleteBillableMetricMutation = { __typename?: 'Mutation', destroyBillableMetric?: { __typename?: 'DestroyBillableMetricPayload', id?: string | null } | null };

export type CouponCaptionFragment = { __typename?: 'Coupon', id: string, amountCurrency?: CurrencyEnum | null, amountCents?: number | null, couponType: CouponTypeEnum, percentageRate?: number | null, frequency: CouponFrequency, frequencyDuration?: number | null };

export type AppliedCouponCaptionFragment = { __typename?: 'AppliedCoupon', id: string, amountCurrency?: CurrencyEnum | null, amountCents?: number | null, percentageRate?: number | null, frequency: CouponFrequency, frequencyDuration?: number | null };

export type CouponItemFragment = { __typename?: 'Coupon', id: string, name: string, customerCount: number, status: CouponStatusEnum, amountCurrency?: CurrencyEnum | null, amountCents?: number | null, canBeDeleted: boolean, expiration: CouponExpiration, expirationDate?: any | null, couponType: CouponTypeEnum, percentageRate?: number | null, frequency: CouponFrequency, frequencyDuration?: number | null };

export type DeleteCouponFragment = { __typename?: 'Coupon', id: string, name: string };

export type DeleteCouponMutationVariables = Exact<{
  input: DestroyCouponInput;
}>;


export type DeleteCouponMutation = { __typename?: 'Mutation', destroyCoupon?: { __typename?: 'DestroyCouponPayload', id?: string | null } | null };

export type TerminateCouponFragment = { __typename?: 'Coupon', id: string, name: string };

export type TerminateCouponMutationVariables = Exact<{
  input: TerminateCouponInput;
}>;


export type TerminateCouponMutation = { __typename?: 'Mutation', terminateCoupon?: { __typename?: 'Coupon', id: string } | null };

export type GetAddOnsForCustomerQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetAddOnsForCustomerQuery = { __typename?: 'Query', addOns: { __typename?: 'AddOnCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, totalPages: number }, collection: Array<{ __typename?: 'AddOn', id: string, name: string, amountCurrency: CurrencyEnum, amountCents: number }> } };

export type AddAddOnMutationVariables = Exact<{
  input: CreateAppliedAddOnInput;
}>;


export type AddAddOnMutation = { __typename?: 'Mutation', createAppliedAddOn?: { __typename?: 'AppliedAddOn', id: string } | null };

export type GetCouponForCustomerQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<CouponStatusEnum>;
}>;


export type GetCouponForCustomerQuery = { __typename?: 'Query', coupons: { __typename?: 'CouponCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, totalPages: number }, collection: Array<{ __typename?: 'Coupon', id: string, name: string, amountCurrency?: CurrencyEnum | null, amountCents?: number | null, couponType: CouponTypeEnum, percentageRate?: number | null, frequency: CouponFrequency, frequencyDuration?: number | null }> } };

export type AddCouponMutationVariables = Exact<{
  input: CreateAppliedCouponInput;
}>;


export type AddCouponMutation = { __typename?: 'Mutation', createAppliedCoupon?: { __typename?: 'AppliedCoupon', id: string } | null };

export type CustomerAddOnsFragment = { __typename?: 'AppliedAddOn', id: string, amountCents: number, amountCurrency: CurrencyEnum, createdAt: any, addOn: { __typename?: 'AddOn', id: string, name: string } };

export type CustomerCouponFragment = { __typename?: 'AppliedCoupon', id: string, amountCurrency?: CurrencyEnum | null, amountCents?: number | null, percentageRate?: number | null, frequency: CouponFrequency, frequencyDuration?: number | null, coupon: { __typename?: 'Coupon', id: string, name: string } };

export type RemoveCouponMutationVariables = Exact<{
  input: TerminateAppliedCouponInput;
}>;


export type RemoveCouponMutation = { __typename?: 'Mutation', terminateAppliedCoupon?: { __typename?: 'AppliedCoupon', id: string } | null };

export type CustomerInvoiceListFragment = { __typename?: 'Invoice', id: string, amountCurrency: CurrencyEnum, issuingDate: any, number: string, status: InvoiceStatusTypeEnum, totalAmountCents: number };

export type DownloadInvoiceMutationVariables = Exact<{
  input: DownloadInvoiceInput;
}>;


export type DownloadInvoiceMutation = { __typename?: 'Mutation', downloadInvoice?: { __typename?: 'Invoice', id: string, fileUrl?: string | null } | null };

export type CustomerItemFragment = { __typename?: 'Customer', id: string, name?: string | null, externalId: string, createdAt: any, canBeDeleted: boolean, activeSubscriptionCount: number, legalName?: string | null, legalNumber?: string | null, phone?: string | null, email?: string | null, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: CountryCode | null, currency?: CurrencyEnum | null, canEditAttributes: boolean, city?: string | null, zipcode?: string | null, paymentProvider?: ProviderTypeEnum | null, stripeCustomer?: { __typename?: 'StripeCustomer', id: string, providerCustomerId?: string | null } | null };

export type CustomerMainInfosFragment = { __typename?: 'CustomerDetails', id: string, name?: string | null, externalId: string, canBeDeleted: boolean, legalName?: string | null, legalNumber?: string | null, phone?: string | null, email?: string | null, currency?: CurrencyEnum | null, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: CountryCode | null, city?: string | null, zipcode?: string | null, paymentProvider?: ProviderTypeEnum | null, stripeCustomer?: { __typename?: 'StripeCustomer', id: string, providerCustomerId?: string | null } | null };

export type VatRateOrganizationFragment = { __typename?: 'Organization', id: string, vatRate: number };

export type CustomerVatRateFragment = { __typename?: 'CustomerDetails', id: string, vatRate?: number | null, name?: string | null };

export type DeleteCustomerDialogFragment = { __typename?: 'Customer', id: string, name?: string | null };

export type DeleteCustomerMutationVariables = Exact<{
  input: DestroyCustomerInput;
}>;


export type DeleteCustomerMutation = { __typename?: 'Mutation', destroyCustomer?: { __typename?: 'DestroyCustomerPayload', id?: string | null } | null };

export type DeleteCustomerVatRateMutationVariables = Exact<{
  input: UpdateCustomerVatRateInput;
}>;


export type DeleteCustomerVatRateMutation = { __typename?: 'Mutation', updateCustomerVatRate?: { __typename?: 'CustomerDetails', id: string, vatRate?: number | null } | null };

export type DeleteCustomerVatRateFragment = { __typename?: 'CustomerDetails', id: string, vatRate?: number | null, name?: string | null };

export type UpdateCustomerVatRateMutationVariables = Exact<{
  input: UpdateCustomerVatRateInput;
}>;


export type UpdateCustomerVatRateMutation = { __typename?: 'Mutation', updateCustomerVatRate?: { __typename?: 'CustomerDetails', id: string, vatRate?: number | null } | null };

export type EditCustomerVatRateFragment = { __typename?: 'CustomerDetails', id: string, name?: string | null, vatRate?: number | null };

export type UpdateCustomerSubscriptionMutationVariables = Exact<{
  input: UpdateSubscriptionInput;
}>;


export type UpdateCustomerSubscriptionMutation = { __typename?: 'Mutation', updateSubscription?: { __typename?: 'Subscription', id: string, name?: string | null, status?: StatusTypeEnum | null, startedAt?: any | null, subscriptionDate?: any | null } | null };

export type SubscriptionItemFragment = { __typename?: 'Subscription', id: string, status?: StatusTypeEnum | null, startedAt?: any | null, nextPendingStartDate?: any | null, name?: string | null, nextName?: string | null, externalId: string, periodEndDate?: any | null, subscriptionDate?: any | null, plan: { __typename?: 'Plan', id: string, name: string, code: string }, nextPlan?: { __typename?: 'Plan', id: string, name: string, code: string } | null };

export type SubscriptionLinePlanFragment = { __typename?: 'Plan', id: string, name: string, code: string };

export type TerminateCustomerSubscriptionMutationVariables = Exact<{
  input: TerminateSubscriptionInput;
}>;


export type TerminateCustomerSubscriptionMutation = { __typename?: 'Mutation', terminateSubscription?: { __typename?: 'Subscription', id: string } | null };

export type CustomerUsageSubscriptionFragment = { __typename?: 'Subscription', id: string, name?: string | null, status?: StatusTypeEnum | null, plan: { __typename?: 'Plan', id: string, name: string, code: string } };

export type CustomerUsageForUsageDetailsFragment = { __typename?: 'CustomerUsage', fromDate: any, toDate: any, chargesUsage: Array<{ __typename?: 'ChargeUsage', billableMetric: { __typename?: 'BillableMetric', name: string }, groups?: Array<{ __typename?: 'GroupUsage', id: string, amountCents: any, key?: string | null, units: number, value: string }> | null }> };

export type CustomerUsageQueryVariables = Exact<{
  customerId: Scalars['ID'];
  subscriptionId: Scalars['ID'];
}>;


export type CustomerUsageQuery = { __typename?: 'Query', customerUsage: { __typename?: 'CustomerUsage', amountCents: any, amountCurrency: CurrencyEnum, fromDate: any, toDate: any, chargesUsage: Array<{ __typename?: 'ChargeUsage', units: number, amountCents: any, billableMetric: { __typename?: 'BillableMetric', id: string, code: string, name: string }, groups?: Array<{ __typename?: 'GroupUsage', id: string, amountCents: any, key?: string | null, units: number, value: string }> | null }> } };

export type EventItemFragment = { __typename?: 'Event', id: string, code: string, externalCustomerId: string, timestamp?: any | null, matchBillableMetric: boolean, matchCustomField: boolean };

export type DeleteWebhookMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;


export type DeleteWebhookMutation = { __typename?: 'Mutation', updateOrganization?: { __typename?: 'Organization', id: string, webhookUrl?: string | null } | null };

export type UpdateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;


export type UpdateOrganizationMutation = { __typename?: 'Mutation', updateOrganization?: { __typename?: 'Organization', id: string, webhookUrl?: string | null } | null };

export type BillableMetricForPlanFragment = { __typename?: 'BillableMetric', id: string, name: string, code: string, flatGroups?: Array<{ __typename?: 'Group', id: string, key?: string | null, value: string }> | null };

export type GetbillableMetricsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetbillableMetricsQuery = { __typename?: 'Query', billableMetrics: { __typename?: 'BillableMetricCollection', collection: Array<{ __typename?: 'BillableMetric', id: string, name: string, code: string, flatGroups?: Array<{ __typename?: 'Group', id: string, key?: string | null, value: string }> | null }> } };

export type ChargeAccordionFragment = { __typename?: 'Charge', id: string, chargeModel: ChargeModelEnum, properties?: { __typename?: 'Properties', amount?: string | null, packageSize?: number | null, freeUnits?: number | null, fixedAmount?: string | null, freeUnitsPerEvents?: number | null, freeUnitsPerTotalAggregation?: string | null, rate?: string | null, graduatedRanges?: Array<{ __typename?: 'GraduatedRange', flatAmount: string, fromValue: number, perUnitAmount: string, toValue?: number | null }> | null, volumeRanges?: Array<{ __typename?: 'VolumeRange', flatAmount: string, fromValue: number, perUnitAmount: string, toValue?: number | null }> | null } | null, groupProperties?: Array<{ __typename?: 'GroupProperties', groupId: string, values: { __typename?: 'Properties', amount?: string | null, packageSize?: number | null, freeUnits?: number | null, fixedAmount?: string | null, freeUnitsPerEvents?: number | null, freeUnitsPerTotalAggregation?: string | null, rate?: string | null, graduatedRanges?: Array<{ __typename?: 'GraduatedRange', flatAmount: string, fromValue: number, perUnitAmount: string, toValue?: number | null }> | null, volumeRanges?: Array<{ __typename?: 'VolumeRange', flatAmount: string, fromValue: number, perUnitAmount: string, toValue?: number | null }> | null } }> | null, billableMetric: { __typename?: 'BillableMetric', id: string, name: string, code: string, flatGroups?: Array<{ __typename?: 'Group', id: string, key?: string | null, value: string }> | null } };

export type PercentageChargeFragment = { __typename?: 'Charge', id: string, properties?: { __typename?: 'Properties', fixedAmount?: string | null, freeUnitsPerEvents?: number | null, freeUnitsPerTotalAggregation?: string | null, rate?: string | null } | null, groupProperties?: Array<{ __typename?: 'GroupProperties', groupId: string, values: { __typename?: 'Properties', fixedAmount?: string | null, freeUnitsPerEvents?: number | null, freeUnitsPerTotalAggregation?: string | null, rate?: string | null } }> | null };

export type DeletePlanDialogFragment = { __typename?: 'Plan', id: string, name: string };

export type DeletePlanMutationVariables = Exact<{
  input: DestroyPlanInput;
}>;


export type DeletePlanMutation = { __typename?: 'Mutation', destroyPlan?: { __typename?: 'DestroyPlanPayload', id?: string | null } | null };

export type GraduatedChargeFragment = { __typename?: 'Charge', id: string, properties?: { __typename?: 'Properties', graduatedRanges?: Array<{ __typename?: 'GraduatedRange', flatAmount: string, fromValue: number, perUnitAmount: string, toValue?: number | null }> | null } | null, groupProperties?: Array<{ __typename?: 'GroupProperties', groupId: string, values: { __typename?: 'Properties', graduatedRanges?: Array<{ __typename?: 'GraduatedRange', flatAmount: string, fromValue: number, perUnitAmount: string, toValue?: number | null }> | null } }> | null };

export type PackageChargeFragment = { __typename?: 'Charge', id: string, properties?: { __typename?: 'Properties', amount?: string | null, packageSize?: number | null, freeUnits?: number | null } | null, groupProperties?: Array<{ __typename?: 'GroupProperties', groupId: string, values: { __typename?: 'Properties', amount?: string | null, packageSize?: number | null, freeUnits?: number | null } }> | null };

export type PlanItemFragment = { __typename?: 'Plan', id: string, name: string, code: string, chargeCount: number, customerCount: number, createdAt: any, canBeDeleted: boolean };

export type VolumeRangesFragment = { __typename?: 'Charge', properties?: { __typename?: 'Properties', volumeRanges?: Array<{ __typename?: 'VolumeRange', flatAmount: string, fromValue: number, perUnitAmount: string, toValue?: number | null }> | null } | null, groupProperties?: Array<{ __typename?: 'GroupProperties', groupId: string, values: { __typename?: 'Properties', volumeRanges?: Array<{ __typename?: 'VolumeRange', flatAmount: string, fromValue: number, perUnitAmount: string, toValue?: number | null }> | null } }> | null };

export type EditOrganizationInformationsDialogFragment = { __typename?: 'Organization', id: string, logoUrl?: string | null, name: string, legalName?: string | null, legalNumber?: string | null, email?: string | null, addressLine1?: string | null, addressLine2?: string | null, zipcode?: string | null, city?: string | null, state?: string | null, country?: CountryCode | null };

export type UpdateOrganizationInformationsMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;


export type UpdateOrganizationInformationsMutation = { __typename?: 'Mutation', updateOrganization?: { __typename?: 'Organization', id: string, logoUrl?: string | null, name: string, legalName?: string | null, legalNumber?: string | null, email?: string | null, addressLine1?: string | null, addressLine2?: string | null, zipcode?: string | null, city?: string | null, state?: string | null, country?: CountryCode | null } | null };

export type EditOrganizationInvoiceTemplateDialogFragment = { __typename?: 'Organization', invoiceFooter?: string | null };

export type UpdateOrganizationInvoiceTemplateMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;


export type UpdateOrganizationInvoiceTemplateMutation = { __typename?: 'Mutation', updateOrganization?: { __typename?: 'Organization', id: string, invoiceFooter?: string | null } | null };

export type UpdateVatRateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;


export type UpdateVatRateOrganizationMutation = { __typename?: 'Mutation', updateOrganization?: { __typename?: 'Organization', id: string, vatRate: number } | null };

export type AddStripeApiKeyMutationVariables = Exact<{
  input: AddStripePaymentProviderInput;
}>;


export type AddStripeApiKeyMutation = { __typename?: 'Mutation', addStripePaymentProvider?: { __typename?: 'StripeProvider', id: string, secretKey: string, createCustomers: boolean } | null };

export type DeleteStripeMutationVariables = Exact<{
  input: DestroyPaymentProviderInput;
}>;


export type DeleteStripeMutation = { __typename?: 'Mutation', destroyPaymentProvider?: { __typename?: 'DestroyPaymentProviderPayload', id?: string | null } | null };

export type CreateInviteMutationVariables = Exact<{
  input: CreateInviteInput;
}>;


export type CreateInviteMutation = { __typename?: 'Mutation', createInvite?: { __typename?: 'Invite', id: string, token: string } | null };

export type InviteItemFragment = { __typename?: 'Invite', id: string, email: string, token: string, organization: { __typename?: 'Organization', id: string, name: string } };

export type MembershipItemFragment = { __typename?: 'Membership', id: string, user: { __typename?: 'User', id: string, email?: string | null }, organization: { __typename?: 'Organization', id: string, name: string } };

export type RevokeInviteMutationVariables = Exact<{
  input: RevokeInviteInput;
}>;


export type RevokeInviteMutation = { __typename?: 'Mutation', revokeInvite?: { __typename?: 'Invite', id: string } | null };

export type RevokeMembershipMutationVariables = Exact<{
  input: RevokeMembershipInput;
}>;


export type RevokeMembershipMutation = { __typename?: 'Mutation', revokeMembership?: { __typename?: 'Membership', id: string } | null };

export type CreateCustomerWalletMutationVariables = Exact<{
  input: CreateCustomerWalletInput;
}>;


export type CreateCustomerWalletMutation = { __typename?: 'Mutation', createCustomerWallet?: { __typename?: 'Wallet', id: string } | null };

export type GetCustomerWalletListQueryVariables = Exact<{
  customerId: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetCustomerWalletListQuery = { __typename?: 'Query', wallets: { __typename?: 'WalletCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, totalPages: number }, collection: Array<{ __typename?: 'Wallet', id: string, currency: CurrencyEnum, rateAmount: string, name?: string | null, expirationDate?: any | null, balance: string, consumedAmount: string, consumedCredits: string, createdAt: any, creditsBalance: string, lastBalanceSyncAt?: any | null, lastConsumedCreditAt?: any | null, status: WalletStatusEnum, terminatedAt?: any | null }> } };

export type TerminateCustomerWalletMutationVariables = Exact<{
  input: TerminateCustomerWalletInput;
}>;


export type TerminateCustomerWalletMutation = { __typename?: 'Mutation', terminateCustomerWallet?: { __typename?: 'Wallet', id: string, status: WalletStatusEnum } | null };

export type CreateCustomerWalletTransactionMutationVariables = Exact<{
  input: CreateCustomerWalletTransactionInput;
}>;


export type CreateCustomerWalletTransactionMutation = { __typename?: 'Mutation', createCustomerWalletTransaction?: { __typename?: 'WalletTransactionCollection', collection: Array<{ __typename?: 'WalletTransaction', id: string }> } | null };

export type WalletForTopupFragment = { __typename?: 'Wallet', id: string, currency: CurrencyEnum, rateAmount: string };

export type UpdateCustomerWalletMutationVariables = Exact<{
  input: UpdateCustomerWalletInput;
}>;


export type UpdateCustomerWalletMutation = { __typename?: 'Mutation', updateCustomerWallet?: { __typename?: 'Wallet', id: string, name?: string | null, expirationDate?: any | null } | null };

export type WalletForUpdateFragment = { __typename?: 'Wallet', id: string, name?: string | null, expirationDate?: any | null };

export type WalletAccordionFragment = { __typename?: 'Wallet', id: string, balance: string, consumedAmount: string, consumedCredits: string, createdAt: any, creditsBalance: string, currency: CurrencyEnum, expirationDate?: any | null, lastBalanceSyncAt?: any | null, lastConsumedCreditAt?: any | null, name?: string | null, rateAmount: string, status: WalletStatusEnum, terminatedAt?: any | null };

export type GetWalletTransactionsQueryVariables = Exact<{
  walletId: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetWalletTransactionsQuery = { __typename?: 'Query', walletTransactions: { __typename?: 'WalletTransactionCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, totalPages: number, totalCount: number }, collection: Array<{ __typename?: 'WalletTransaction', id: string, status: WalletTransactionStatusEnum, transactionType: WalletTransactionTransactionTypeEnum, amount: string, creditAmount: string, settledAt?: any | null, createdAt: any }> } };

export type WalletInfosForTransactionsFragment = { __typename?: 'Wallet', id: string, currency: CurrencyEnum, status: WalletStatusEnum };

export type AddSubscriptionPlanFragment = { __typename?: 'Plan', id: string, name: string, code: string, interval: PlanInterval };

export type GetPlansQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetPlansQuery = { __typename?: 'Query', plans: { __typename?: 'PlanCollection', collection: Array<{ __typename?: 'Plan', id: string, name: string, code: string, interval: PlanInterval }> } };

export type CreateSubscriptionMutationVariables = Exact<{
  input: CreateSubscriptionInput;
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', createSubscription?: { __typename?: 'Subscription', id: string, status?: StatusTypeEnum | null, startedAt?: any | null, nextPendingStartDate?: any | null, name?: string | null, nextName?: string | null, externalId: string, periodEndDate?: any | null, subscriptionDate?: any | null, plan: { __typename?: 'Plan', id: string, name: string, code: string }, nextPlan?: { __typename?: 'Plan', id: string, name: string, code: string } | null } | null };

export type GetSinglePlanQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSinglePlanQuery = { __typename?: 'Query', plan?: { __typename?: 'PlanDetails', id: string, name: string, code: string, description?: string | null, interval: PlanInterval, payInAdvance: boolean, amountCents: number, amountCurrency: CurrencyEnum, trialPeriod?: number | null, canBeDeleted: boolean, billChargesMonthly?: boolean | null, charges?: Array<{ __typename?: 'Charge', id: string, chargeModel: ChargeModelEnum, billableMetric: { __typename?: 'BillableMetric', id: string, name: string, code: string, flatGroups?: Array<{ __typename?: 'Group', id: string, key?: string | null, value: string }> | null }, properties?: { __typename?: 'Properties', amount?: string | null, packageSize?: number | null, freeUnits?: number | null, fixedAmount?: string | null, freeUnitsPerEvents?: number | null, freeUnitsPerTotalAggregation?: string | null, rate?: string | null, graduatedRanges?: Array<{ __typename?: 'GraduatedRange', flatAmount: string, fromValue: number, perUnitAmount: string, toValue?: number | null }> | null, volumeRanges?: Array<{ __typename?: 'VolumeRange', flatAmount: string, fromValue: number, perUnitAmount: string, toValue?: number | null }> | null } | null, groupProperties?: Array<{ __typename?: 'GroupProperties', groupId: string, values: { __typename?: 'Properties', amount?: string | null, packageSize?: number | null, freeUnits?: number | null, fixedAmount?: string | null, freeUnitsPerEvents?: number | null, freeUnitsPerTotalAggregation?: string | null, rate?: string | null, graduatedRanges?: Array<{ __typename?: 'GraduatedRange', flatAmount: string, fromValue: number, perUnitAmount: string, toValue?: number | null }> | null, volumeRanges?: Array<{ __typename?: 'VolumeRange', flatAmount: string, fromValue: number, perUnitAmount: string, toValue?: number | null }> | null } }> | null }> | null } | null };

export type CreatePlanMutationVariables = Exact<{
  input: CreatePlanInput;
}>;


export type CreatePlanMutation = { __typename?: 'Mutation', createPlan?: { __typename?: 'Plan', id: string } | null };

export type UpdatePlanMutationVariables = Exact<{
  input: UpdatePlanInput;
}>;


export type UpdatePlanMutation = { __typename?: 'Mutation', updatePlan?: { __typename?: 'Plan', id: string, name: string, code: string, chargeCount: number, customerCount: number, createdAt: any, canBeDeleted: boolean } | null };

export type EditAddOnFragment = { __typename?: 'AddOnDetails', id: string, name: string, code: string, description?: string | null, amountCents: number, amountCurrency: CurrencyEnum };

export type GetSingleAddOnQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSingleAddOnQuery = { __typename?: 'Query', addOn?: { __typename?: 'AddOnDetails', id: string, name: string, code: string, description?: string | null, amountCents: number, amountCurrency: CurrencyEnum } | null };

export type CreateAddOnMutationVariables = Exact<{
  input: CreateAddOnInput;
}>;


export type CreateAddOnMutation = { __typename?: 'Mutation', createAddOn?: { __typename?: 'AddOn', id: string } | null };

export type UpdateAddOnMutationVariables = Exact<{
  input: UpdateAddOnInput;
}>;


export type UpdateAddOnMutation = { __typename?: 'Mutation', updateAddOn?: { __typename?: 'AddOn', id: string, name: string, amountCurrency: CurrencyEnum, amountCents: number, customerCount: number, createdAt: any, canBeDeleted: boolean } | null };

export type EditBillableMetricFragment = { __typename?: 'BillableMetricDetail', id: string, name: string, code: string, group?: any | null, description?: string | null, aggregationType: AggregationTypeEnum, canBeDeleted: boolean, fieldName?: string | null };

export type GetSingleBillableMetricQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSingleBillableMetricQuery = { __typename?: 'Query', billableMetric?: { __typename?: 'BillableMetricDetail', id: string, name: string, code: string, group?: any | null, description?: string | null, aggregationType: AggregationTypeEnum, canBeDeleted: boolean, fieldName?: string | null } | null };

export type CreateBillableMetricMutationVariables = Exact<{
  input: CreateBillableMetricInput;
}>;


export type CreateBillableMetricMutation = { __typename?: 'Mutation', createBillableMetric?: { __typename?: 'BillableMetric', id: string, group?: any | null } | null };

export type UpdateBillableMetricMutationVariables = Exact<{
  input: UpdateBillableMetricInput;
}>;


export type UpdateBillableMetricMutation = { __typename?: 'Mutation', updateBillableMetric?: { __typename?: 'BillableMetric', id: string, name: string, code: string, createdAt: any, canBeDeleted: boolean } | null };

export type EditCouponFragment = { __typename?: 'CouponDetails', id: string, amountCents?: number | null, name: string, amountCurrency?: CurrencyEnum | null, code?: string | null, expiration: CouponExpiration, expirationDate?: any | null, canBeDeleted: boolean, couponType: CouponTypeEnum, percentageRate?: number | null, frequency: CouponFrequency, frequencyDuration?: number | null };

export type GetSingleCouponQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSingleCouponQuery = { __typename?: 'Query', coupon?: { __typename?: 'CouponDetails', id: string, amountCents?: number | null, name: string, amountCurrency?: CurrencyEnum | null, code?: string | null, expiration: CouponExpiration, expirationDate?: any | null, canBeDeleted: boolean, couponType: CouponTypeEnum, percentageRate?: number | null, frequency: CouponFrequency, frequencyDuration?: number | null } | null };

export type CreateCouponMutationVariables = Exact<{
  input: CreateCouponInput;
}>;


export type CreateCouponMutation = { __typename?: 'Mutation', createCoupon?: { __typename?: 'Coupon', id: string } | null };

export type UpdateCouponMutationVariables = Exact<{
  input: UpdateCouponInput;
}>;


export type UpdateCouponMutation = { __typename?: 'Mutation', updateCoupon?: { __typename?: 'Coupon', id: string, name: string, customerCount: number, status: CouponStatusEnum, amountCurrency?: CurrencyEnum | null, amountCents?: number | null, canBeDeleted: boolean, expiration: CouponExpiration, expirationDate?: any | null, couponType: CouponTypeEnum, percentageRate?: number | null, frequency: CouponFrequency, frequencyDuration?: number | null } | null };

export type AddCustomerDrawerFragment = { __typename?: 'Customer', id: string, name?: string | null, externalId: string, canBeDeleted: boolean, legalName?: string | null, legalNumber?: string | null, phone?: string | null, email?: string | null, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: CountryCode | null, currency?: CurrencyEnum | null, canEditAttributes: boolean, city?: string | null, zipcode?: string | null, paymentProvider?: ProviderTypeEnum | null, stripeCustomer?: { __typename?: 'StripeCustomer', id: string, providerCustomerId?: string | null } | null };

export type AddCustomerDrawerDetailFragment = { __typename?: 'CustomerDetails', id: string, name?: string | null, externalId: string, canBeDeleted: boolean, legalName?: string | null, legalNumber?: string | null, phone?: string | null, email?: string | null, currency?: CurrencyEnum | null, canEditAttributes: boolean, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: CountryCode | null, city?: string | null, zipcode?: string | null, paymentProvider?: ProviderTypeEnum | null, stripeCustomer?: { __typename?: 'StripeCustomer', id: string, providerCustomerId?: string | null } | null };

export type CreateCustomerMutationVariables = Exact<{
  input: CreateCustomerInput;
}>;


export type CreateCustomerMutation = { __typename?: 'Mutation', createCustomer?: { __typename?: 'Customer', id: string, name?: string | null, externalId: string, canBeDeleted: boolean, legalName?: string | null, legalNumber?: string | null, phone?: string | null, email?: string | null, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: CountryCode | null, currency?: CurrencyEnum | null, canEditAttributes: boolean, city?: string | null, zipcode?: string | null, paymentProvider?: ProviderTypeEnum | null, createdAt: any, activeSubscriptionCount: number, stripeCustomer?: { __typename?: 'StripeCustomer', id: string, providerCustomerId?: string | null } | null } | null };

export type UpdateCustomerMutationVariables = Exact<{
  input: UpdateCustomerInput;
}>;


export type UpdateCustomerMutation = { __typename?: 'Mutation', updateCustomer?: { __typename?: 'Customer', id: string, name?: string | null, externalId: string, canBeDeleted: boolean, legalName?: string | null, legalNumber?: string | null, phone?: string | null, email?: string | null, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: CountryCode | null, currency?: CurrencyEnum | null, canEditAttributes: boolean, city?: string | null, zipcode?: string | null, paymentProvider?: ProviderTypeEnum | null, createdAt: any, activeSubscriptionCount: number, stripeCustomer?: { __typename?: 'StripeCustomer', id: string, providerCustomerId?: string | null } | null } | null };

export type GetInvoiceDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetInvoiceDetailsQuery = { __typename?: 'Query', invoice?: { __typename?: 'Invoice', id: string, number: string, status: InvoiceStatusTypeEnum, totalAmountCents: number, totalAmountCurrency: CurrencyEnum } | null };

export type CurrentVersionQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentVersionQuery = { __typename?: 'Query', currentVersion: { __typename?: 'CurrentVersion', githubUrl: string, number: string } };

export type AddOnsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type AddOnsQuery = { __typename?: 'Query', addOns: { __typename?: 'AddOnCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, totalPages: number }, collection: Array<{ __typename?: 'AddOn', id: string, name: string, amountCurrency: CurrencyEnum, amountCents: number, customerCount: number, createdAt: any, canBeDeleted: boolean }> } };

export type BillableMetricsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type BillableMetricsQuery = { __typename?: 'Query', billableMetrics: { __typename?: 'BillableMetricCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, totalPages: number }, collection: Array<{ __typename?: 'BillableMetric', id: string, name: string, code: string, createdAt: any, canBeDeleted: boolean }> } };

export type CouponsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type CouponsQuery = { __typename?: 'Query', coupons: { __typename?: 'CouponCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, totalPages: number }, collection: Array<{ __typename?: 'Coupon', id: string, name: string, customerCount: number, status: CouponStatusEnum, amountCurrency?: CurrencyEnum | null, amountCents?: number | null, canBeDeleted: boolean, expiration: CouponExpiration, expirationDate?: any | null, couponType: CouponTypeEnum, percentageRate?: number | null, frequency: CouponFrequency, frequencyDuration?: number | null }> } };

export type EditPlanFragment = { __typename?: 'PlanDetails', id: string, name: string, code: string, description?: string | null, interval: PlanInterval, payInAdvance: boolean, amountCents: number, amountCurrency: CurrencyEnum, trialPeriod?: number | null, canBeDeleted: boolean, billChargesMonthly?: boolean | null, charges?: Array<{ __typename?: 'Charge', id: string, chargeModel: ChargeModelEnum, billableMetric: { __typename?: 'BillableMetric', id: string, name: string, code: string, flatGroups?: Array<{ __typename?: 'Group', id: string, key?: string | null, value: string }> | null }, properties?: { __typename?: 'Properties', amount?: string | null, packageSize?: number | null, freeUnits?: number | null, fixedAmount?: string | null, freeUnitsPerEvents?: number | null, freeUnitsPerTotalAggregation?: string | null, rate?: string | null, graduatedRanges?: Array<{ __typename?: 'GraduatedRange', flatAmount: string, fromValue: number, perUnitAmount: string, toValue?: number | null }> | null, volumeRanges?: Array<{ __typename?: 'VolumeRange', flatAmount: string, fromValue: number, perUnitAmount: string, toValue?: number | null }> | null } | null, groupProperties?: Array<{ __typename?: 'GroupProperties', groupId: string, values: { __typename?: 'Properties', amount?: string | null, packageSize?: number | null, freeUnits?: number | null, fixedAmount?: string | null, freeUnitsPerEvents?: number | null, freeUnitsPerTotalAggregation?: string | null, rate?: string | null, graduatedRanges?: Array<{ __typename?: 'GraduatedRange', flatAmount: string, fromValue: number, perUnitAmount: string, toValue?: number | null }> | null, volumeRanges?: Array<{ __typename?: 'VolumeRange', flatAmount: string, fromValue: number, perUnitAmount: string, toValue?: number | null }> | null } }> | null }> | null };

export type CustomerDetailsFragment = { __typename?: 'CustomerDetails', id: string, name?: string | null, externalId: string, canBeDeleted: boolean, hasActiveWallet: boolean, currency?: CurrencyEnum | null, vatRate?: number | null, legalName?: string | null, legalNumber?: string | null, phone?: string | null, email?: string | null, canEditAttributes: boolean, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: CountryCode | null, city?: string | null, zipcode?: string | null, paymentProvider?: ProviderTypeEnum | null, subscriptions: Array<{ __typename?: 'Subscription', id: string, status?: StatusTypeEnum | null, startedAt?: any | null, nextPendingStartDate?: any | null, name?: string | null, nextName?: string | null, externalId: string, periodEndDate?: any | null, subscriptionDate?: any | null, plan: { __typename?: 'Plan', id: string, amountCurrency: CurrencyEnum, name: string, code: string }, nextPlan?: { __typename?: 'Plan', id: string, name: string, code: string } | null }>, invoices?: Array<{ __typename?: 'Invoice', id: string, amountCurrency: CurrencyEnum, issuingDate: any, number: string, status: InvoiceStatusTypeEnum, totalAmountCents: number }> | null, appliedCoupons?: Array<{ __typename?: 'AppliedCoupon', id: string, amountCurrency?: CurrencyEnum | null, amountCents?: number | null, percentageRate?: number | null, frequency: CouponFrequency, frequencyDuration?: number | null, coupon: { __typename?: 'Coupon', id: string, name: string } }> | null, appliedAddOns?: Array<{ __typename?: 'AppliedAddOn', id: string, amountCents: number, amountCurrency: CurrencyEnum, createdAt: any, addOn: { __typename?: 'AddOn', id: string, name: string } }> | null, stripeCustomer?: { __typename?: 'StripeCustomer', id: string, providerCustomerId?: string | null } | null };

export type GetCustomerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCustomerQuery = { __typename?: 'Query', customer?: { __typename?: 'CustomerDetails', id: string, name?: string | null, externalId: string, canBeDeleted: boolean, hasActiveWallet: boolean, currency?: CurrencyEnum | null, vatRate?: number | null, legalName?: string | null, legalNumber?: string | null, phone?: string | null, email?: string | null, canEditAttributes: boolean, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: CountryCode | null, city?: string | null, zipcode?: string | null, paymentProvider?: ProviderTypeEnum | null, subscriptions: Array<{ __typename?: 'Subscription', id: string, status?: StatusTypeEnum | null, startedAt?: any | null, nextPendingStartDate?: any | null, name?: string | null, nextName?: string | null, externalId: string, periodEndDate?: any | null, subscriptionDate?: any | null, plan: { __typename?: 'Plan', id: string, amountCurrency: CurrencyEnum, name: string, code: string }, nextPlan?: { __typename?: 'Plan', id: string, name: string, code: string } | null }>, invoices?: Array<{ __typename?: 'Invoice', id: string, amountCurrency: CurrencyEnum, issuingDate: any, number: string, status: InvoiceStatusTypeEnum, totalAmountCents: number }> | null, appliedCoupons?: Array<{ __typename?: 'AppliedCoupon', id: string, amountCurrency?: CurrencyEnum | null, amountCents?: number | null, percentageRate?: number | null, frequency: CouponFrequency, frequencyDuration?: number | null, coupon: { __typename?: 'Coupon', id: string, name: string } }> | null, appliedAddOns?: Array<{ __typename?: 'AppliedAddOn', id: string, amountCents: number, amountCurrency: CurrencyEnum, createdAt: any, addOn: { __typename?: 'AddOn', id: string, name: string } }> | null, stripeCustomer?: { __typename?: 'StripeCustomer', id: string, providerCustomerId?: string | null } | null } | null };

export type CustomersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type CustomersQuery = { __typename?: 'Query', customers: { __typename?: 'CustomerCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, totalPages: number }, collection: Array<{ __typename?: 'Customer', id: string, name?: string | null, externalId: string, createdAt: any, canBeDeleted: boolean, activeSubscriptionCount: number, legalName?: string | null, legalNumber?: string | null, phone?: string | null, email?: string | null, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: CountryCode | null, currency?: CurrencyEnum | null, canEditAttributes: boolean, city?: string | null, zipcode?: string | null, paymentProvider?: ProviderTypeEnum | null, stripeCustomer?: { __typename?: 'StripeCustomer', id: string, providerCustomerId?: string | null } | null }> } };

export type GetinviteQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type GetinviteQuery = { __typename?: 'Query', invite?: { __typename?: 'Invite', id: string, email: string, organization: { __typename?: 'Organization', id: string, name: string } } | null };

export type AcceptInviteMutationVariables = Exact<{
  input: AcceptInviteInput;
}>;


export type AcceptInviteMutation = { __typename?: 'Mutation', acceptInvite?: { __typename?: 'RegisterUser', token: string, user: { __typename?: 'User', id: string, email?: string | null, organizations?: Array<{ __typename?: 'Organization', id: string, name: string, logoUrl?: string | null, apiKey: string, vatRate: number }> | null } } | null };

export type PlansQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type PlansQuery = { __typename?: 'Query', plans: { __typename?: 'PlanCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, totalPages: number }, collection: Array<{ __typename?: 'Plan', id: string, name: string, code: string, chargeCount: number, customerCount: number, createdAt: any, canBeDeleted: boolean }> } };

export type LoginUserMutationVariables = Exact<{
  input: LoginUserInput;
}>;


export type LoginUserMutation = { __typename?: 'Mutation', loginUser?: { __typename?: 'LoginUser', token: string, user: { __typename?: 'User', id: string, email?: string | null, organizations?: Array<{ __typename?: 'Organization', id: string, name: string, logoUrl?: string | null, apiKey: string, vatRate: number }> | null } } | null };

export type SignupMutationVariables = Exact<{
  input: RegisterUserInput;
}>;


export type SignupMutation = { __typename?: 'Mutation', registerUser?: { __typename?: 'RegisterUser', token: string, user: { __typename?: 'User', id: string, email?: string | null, organizations?: Array<{ __typename?: 'Organization', id: string, name: string, logoUrl?: string | null, apiKey: string, vatRate: number }> | null }, organization: { __typename?: 'Organization', id: string, name: string } } | null };

export type ApiKeyOrganizationFragment = { __typename?: 'Organization', id: string, apiKey: string };

export type EventListFragment = { __typename?: 'Event', id: string, code: string, externalCustomerId: string, transactionId?: string | null, timestamp?: any | null, receivedAt: any, payload: any, billableMetricName?: string | null, matchBillableMetric: boolean, matchCustomField: boolean, apiClient?: string | null, ipAddress?: string | null, externalSubscriptionId: string };

export type EventsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type EventsQuery = { __typename?: 'Query', events?: { __typename?: 'EventCollection', collection: Array<{ __typename?: 'Event', id: string, code: string, externalCustomerId: string, transactionId?: string | null, timestamp?: any | null, receivedAt: any, payload: any, billableMetricName?: string | null, matchBillableMetric: boolean, matchCustomField: boolean, apiClient?: string | null, ipAddress?: string | null, externalSubscriptionId: string }>, metadata: { __typename?: 'CollectionMetadata', currentPage: number, totalPages: number } } | null };

export type WehbookSettingQueryVariables = Exact<{ [key: string]: never; }>;


export type WehbookSettingQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string, organizations?: Array<{ __typename?: 'Organization', id: string, webhookUrl?: string | null }> | null } };

export type IntegrationsSettingQueryVariables = Exact<{ [key: string]: never; }>;


export type IntegrationsSettingQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string, organizations?: Array<{ __typename?: 'Organization', id: string, stripePaymentProvider?: { __typename?: 'StripeProvider', id: string } | null }> | null } };

export type GetInvitesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetInvitesQuery = { __typename?: 'Query', invites: { __typename?: 'InviteCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, totalPages: number, totalCount: number }, collection: Array<{ __typename?: 'Invite', id: string, email: string, token: string, organization: { __typename?: 'Organization', id: string, name: string } }> } };

export type GetMembersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetMembersQuery = { __typename?: 'Query', memberships: { __typename?: 'MembershipCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, totalPages: number, totalCount: number }, collection: Array<{ __typename?: 'Membership', id: string, user: { __typename?: 'User', id: string, email?: string | null }, organization: { __typename?: 'Organization', id: string, name: string } }> } };

export type OrganizationInformationsFragment = { __typename?: 'Organization', id: string, logoUrl?: string | null, name: string, legalName?: string | null, legalNumber?: string | null, email?: string | null, addressLine1?: string | null, addressLine2?: string | null, zipcode?: string | null, city?: string | null, state?: string | null, country?: CountryCode | null };

export type GetOrganizationInformationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationInformationsQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', organizations?: Array<{ __typename?: 'Organization', id: string, logoUrl?: string | null, name: string, legalName?: string | null, legalNumber?: string | null, email?: string | null, addressLine1?: string | null, addressLine2?: string | null, zipcode?: string | null, city?: string | null, state?: string | null, country?: CountryCode | null }> | null } };

export type StripeIntegrationFragment = { __typename?: 'StripeProvider', id: string, secretKey: string, createCustomers: boolean };

export type StripeIntegrationsSettingQueryVariables = Exact<{ [key: string]: never; }>;


export type StripeIntegrationsSettingQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string, organizations?: Array<{ __typename?: 'Organization', id: string, stripePaymentProvider?: { __typename?: 'StripeProvider', id: string, secretKey: string, createCustomers: boolean } | null }> | null } };

export type UpdateStripeIntegrationMutationVariables = Exact<{
  input: AddStripePaymentProviderInput;
}>;


export type UpdateStripeIntegrationMutation = { __typename?: 'Mutation', addStripePaymentProvider?: { __typename?: 'StripeProvider', id: string, secretKey: string, createCustomers: boolean } | null };

export type OrganizationInvoiceTemplateFragment = { __typename?: 'Organization', invoiceFooter?: string | null };

export type GetOrganizationInvoiceAndTaxInformationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationInvoiceAndTaxInformationsQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string, organizations?: Array<{ __typename?: 'Organization', id: string, vatRate: number, invoiceFooter?: string | null }> | null } };

export type GetAllInvoiceDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAllInvoiceDetailsQuery = { __typename?: 'Query', invoice?: { __typename?: 'Invoice', id: string, number: string, issuingDate: any, vatAmountCents: number, vatAmountCurrency: CurrencyEnum, totalAmountCents: number, totalAmountCurrency: CurrencyEnum, walletTransactionAmountCents: number, subtotalBeforePrepaidCredits: string, creditAmountCents: number, creditAmountCurrency: CurrencyEnum, customer: { __typename?: 'Customer', id: string, currency?: CurrencyEnum | null, name?: string | null, legalName?: string | null, email?: string | null, addressLine1?: string | null, addressLine2?: string | null, state?: string | null, country?: CountryCode | null, city?: string | null, zipcode?: string | null }, invoiceSubscriptions?: Array<{ __typename?: 'InvoiceSubscription', subscription: { __typename?: 'Subscription', id: string, name?: string | null, subscriptionDate?: any | null, periodEndDate?: any | null, plan: { __typename?: 'Plan', id: string, name: string, interval: PlanInterval, amountCents: number, amountCurrency: CurrencyEnum } }, fees?: Array<{ __typename?: 'Fee', id: string, amountCents: any, eventsCount?: any | null, units: number, feeType: FeeTypesEnum, charge?: { __typename?: 'Charge', id: string, billableMetric: { __typename?: 'BillableMetric', id: string, name: string, aggregationType: AggregationTypeEnum } } | null, group?: { __typename?: 'Group', id: string, key?: string | null, value: string } | null }> | null }> | null } | null };

export const ApiKeyOrganizationFragmentDoc = gql`
    fragment ApiKeyOrganization on Organization {
  id
  apiKey
}
    `;
export const VatRateOrganizationFragmentDoc = gql`
    fragment VatRateOrganization on Organization {
  id
  vatRate
}
    `;
export const CurrentOrganizationFragmentDoc = gql`
    fragment CurrentOrganization on Organization {
  id
  name
  logoUrl
  ...ApiKeyOrganization
  ...VatRateOrganization
}
    ${ApiKeyOrganizationFragmentDoc}
${VatRateOrganizationFragmentDoc}`;
export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on User {
  id
  email
  organizations {
    ...CurrentOrganization
  }
}
    ${CurrentOrganizationFragmentDoc}`;
export const AddOnItemFragmentDoc = gql`
    fragment AddOnItem on AddOn {
  id
  name
  amountCurrency
  amountCents
  customerCount
  createdAt
  canBeDeleted
}
    `;
export const DeleteAddOnFragmentDoc = gql`
    fragment DeleteAddOn on AddOn {
  id
  name
}
    `;
export const DeleteBillableMetricDialogFragmentDoc = gql`
    fragment DeleteBillableMetricDialog on BillableMetric {
  id
  name
}
    `;
export const BillableMetricItemFragmentDoc = gql`
    fragment BillableMetricItem on BillableMetric {
  id
  name
  code
  createdAt
  canBeDeleted
  ...DeleteBillableMetricDialog
}
    ${DeleteBillableMetricDialogFragmentDoc}`;
export const CouponCaptionFragmentDoc = gql`
    fragment CouponCaption on Coupon {
  id
  amountCurrency
  amountCents
  couponType
  percentageRate
  frequency
  frequencyDuration
}
    `;
export const CouponItemFragmentDoc = gql`
    fragment CouponItem on Coupon {
  id
  name
  customerCount
  status
  amountCurrency
  amountCents
  canBeDeleted
  expiration
  expirationDate
  couponType
  percentageRate
  frequency
  frequencyDuration
}
    `;
export const DeleteCouponFragmentDoc = gql`
    fragment DeleteCoupon on Coupon {
  id
  name
}
    `;
export const TerminateCouponFragmentDoc = gql`
    fragment TerminateCoupon on Coupon {
  id
  name
}
    `;
export const AddCustomerDrawerFragmentDoc = gql`
    fragment AddCustomerDrawer on Customer {
  id
  name
  externalId
  canBeDeleted
  legalName
  legalNumber
  phone
  email
  addressLine1
  addressLine2
  state
  country
  currency
  canEditAttributes
  city
  zipcode
  paymentProvider
  stripeCustomer {
    id
    providerCustomerId
  }
}
    `;
export const CustomerItemFragmentDoc = gql`
    fragment CustomerItem on Customer {
  id
  name
  externalId
  createdAt
  canBeDeleted
  activeSubscriptionCount
  ...AddCustomerDrawer
}
    ${AddCustomerDrawerFragmentDoc}`;
export const DeleteCustomerDialogFragmentDoc = gql`
    fragment DeleteCustomerDialog on Customer {
  id
  name
}
    `;
export const CustomerUsageForUsageDetailsFragmentDoc = gql`
    fragment CustomerUsageForUsageDetails on CustomerUsage {
  fromDate
  toDate
  chargesUsage {
    billableMetric {
      name
    }
    groups {
      id
      amountCents
      key
      units
      value
    }
  }
}
    `;
export const BillableMetricForPlanFragmentDoc = gql`
    fragment billableMetricForPlan on BillableMetric {
  id
  name
  code
  flatGroups {
    id
    key
    value
  }
}
    `;
export const DeletePlanDialogFragmentDoc = gql`
    fragment DeletePlanDialog on Plan {
  id
  name
}
    `;
export const PlanItemFragmentDoc = gql`
    fragment PlanItem on Plan {
  id
  name
  code
  chargeCount
  customerCount
  createdAt
  canBeDeleted
  ...DeletePlanDialog
}
    ${DeletePlanDialogFragmentDoc}`;
export const EditOrganizationInformationsDialogFragmentDoc = gql`
    fragment EditOrganizationInformationsDialog on Organization {
  id
  logoUrl
  name
  legalName
  legalNumber
  email
  addressLine1
  addressLine2
  zipcode
  city
  state
  country
}
    `;
export const EditOrganizationInvoiceTemplateDialogFragmentDoc = gql`
    fragment EditOrganizationInvoiceTemplateDialog on Organization {
  invoiceFooter
}
    `;
export const InviteItemFragmentDoc = gql`
    fragment InviteItem on Invite {
  id
  email
  token
  organization {
    id
    name
  }
}
    `;
export const MembershipItemFragmentDoc = gql`
    fragment MembershipItem on Membership {
  id
  user {
    id
    email
  }
  organization {
    id
    name
  }
}
    `;
export const WalletForTopupFragmentDoc = gql`
    fragment WalletForTopup on Wallet {
  id
  currency
  rateAmount
}
    `;
export const WalletForUpdateFragmentDoc = gql`
    fragment WalletForUpdate on Wallet {
  id
  name
  expirationDate
}
    `;
export const WalletAccordionFragmentDoc = gql`
    fragment WalletAccordion on Wallet {
  id
  balance
  consumedAmount
  consumedCredits
  createdAt
  creditsBalance
  currency
  expirationDate
  lastBalanceSyncAt
  lastConsumedCreditAt
  name
  rateAmount
  status
  terminatedAt
}
    `;
export const WalletInfosForTransactionsFragmentDoc = gql`
    fragment WalletInfosForTransactions on Wallet {
  id
  currency
  status
}
    `;
export const AddSubscriptionPlanFragmentDoc = gql`
    fragment AddSubscriptionPlan on Plan {
  id
  name
  code
  interval
}
    `;
export const EditAddOnFragmentDoc = gql`
    fragment EditAddOn on AddOnDetails {
  id
  name
  code
  description
  amountCents
  amountCurrency
}
    `;
export const EditBillableMetricFragmentDoc = gql`
    fragment EditBillableMetric on BillableMetricDetail {
  id
  name
  code
  group
  description
  aggregationType
  canBeDeleted
  fieldName
}
    `;
export const EditCouponFragmentDoc = gql`
    fragment EditCoupon on CouponDetails {
  id
  amountCents
  name
  amountCurrency
  code
  expiration
  expirationDate
  canBeDeleted
  couponType
  percentageRate
  frequency
  frequencyDuration
}
    `;
export const GraduatedChargeFragmentDoc = gql`
    fragment GraduatedCharge on Charge {
  id
  properties {
    graduatedRanges {
      flatAmount
      fromValue
      perUnitAmount
      toValue
    }
  }
  groupProperties {
    groupId
    values {
      graduatedRanges {
        flatAmount
        fromValue
        perUnitAmount
        toValue
      }
    }
  }
}
    `;
export const VolumeRangesFragmentDoc = gql`
    fragment VolumeRanges on Charge {
  properties {
    volumeRanges {
      flatAmount
      fromValue
      perUnitAmount
      toValue
    }
  }
  groupProperties {
    groupId
    values {
      volumeRanges {
        flatAmount
        fromValue
        perUnitAmount
        toValue
      }
    }
  }
}
    `;
export const PackageChargeFragmentDoc = gql`
    fragment PackageCharge on Charge {
  id
  properties {
    amount
    packageSize
    freeUnits
  }
  groupProperties {
    groupId
    values {
      amount
      packageSize
      freeUnits
    }
  }
}
    `;
export const PercentageChargeFragmentDoc = gql`
    fragment PercentageCharge on Charge {
  id
  properties {
    fixedAmount
    freeUnitsPerEvents
    freeUnitsPerTotalAggregation
    rate
  }
  groupProperties {
    groupId
    values {
      fixedAmount
      freeUnitsPerEvents
      freeUnitsPerTotalAggregation
      rate
    }
  }
}
    `;
export const ChargeAccordionFragmentDoc = gql`
    fragment ChargeAccordion on Charge {
  id
  chargeModel
  properties {
    amount
  }
  groupProperties {
    groupId
    values {
      amount
    }
  }
  billableMetric {
    id
    name
    code
    flatGroups {
      id
      key
      value
    }
  }
  ...GraduatedCharge
  ...VolumeRanges
  ...PackageCharge
  ...PercentageCharge
}
    ${GraduatedChargeFragmentDoc}
${VolumeRangesFragmentDoc}
${PackageChargeFragmentDoc}
${PercentageChargeFragmentDoc}`;
export const EditPlanFragmentDoc = gql`
    fragment EditPlan on PlanDetails {
  id
  name
  code
  description
  interval
  payInAdvance
  amountCents
  amountCurrency
  trialPeriod
  canBeDeleted
  billChargesMonthly
  charges {
    id
    billableMetric {
      id
    }
    ...ChargeAccordion
    chargeModel
  }
}
    ${ChargeAccordionFragmentDoc}`;
export const SubscriptionLinePlanFragmentDoc = gql`
    fragment SubscriptionLinePlan on Plan {
  id
  name
  code
}
    `;
export const SubscriptionItemFragmentDoc = gql`
    fragment SubscriptionItem on Subscription {
  id
  status
  startedAt
  nextPendingStartDate
  name
  nextName
  externalId
  periodEndDate
  subscriptionDate
  plan {
    ...SubscriptionLinePlan
  }
  nextPlan {
    ...SubscriptionLinePlan
  }
}
    ${SubscriptionLinePlanFragmentDoc}`;
export const CustomerUsageSubscriptionFragmentDoc = gql`
    fragment CustomerUsageSubscription on Subscription {
  id
  name
  status
  plan {
    id
    name
    code
  }
}
    `;
export const CustomerInvoiceListFragmentDoc = gql`
    fragment CustomerInvoiceList on Invoice {
  id
  amountCurrency
  issuingDate
  number
  status
  totalAmountCents
}
    `;
export const AppliedCouponCaptionFragmentDoc = gql`
    fragment AppliedCouponCaption on AppliedCoupon {
  id
  amountCurrency
  amountCents
  percentageRate
  frequency
  frequencyDuration
}
    `;
export const CustomerCouponFragmentDoc = gql`
    fragment CustomerCoupon on AppliedCoupon {
  id
  ...AppliedCouponCaption
  coupon {
    id
    name
  }
}
    ${AppliedCouponCaptionFragmentDoc}`;
export const CustomerAddOnsFragmentDoc = gql`
    fragment CustomerAddOns on AppliedAddOn {
  id
  amountCents
  amountCurrency
  createdAt
  addOn {
    id
    name
  }
}
    `;
export const EditCustomerVatRateFragmentDoc = gql`
    fragment EditCustomerVatRate on CustomerDetails {
  id
  name
  vatRate
}
    `;
export const DeleteCustomerVatRateFragmentDoc = gql`
    fragment DeleteCustomerVatRate on CustomerDetails {
  id
  vatRate
  name
}
    `;
export const CustomerVatRateFragmentDoc = gql`
    fragment CustomerVatRate on CustomerDetails {
  id
  vatRate
  ...EditCustomerVatRate
  ...DeleteCustomerVatRate
}
    ${EditCustomerVatRateFragmentDoc}
${DeleteCustomerVatRateFragmentDoc}`;
export const AddCustomerDrawerDetailFragmentDoc = gql`
    fragment AddCustomerDrawerDetail on CustomerDetails {
  id
  name
  externalId
  canBeDeleted
  legalName
  legalNumber
  phone
  email
  currency
  canEditAttributes
  addressLine1
  addressLine2
  state
  country
  city
  zipcode
  paymentProvider
  stripeCustomer {
    id
    providerCustomerId
  }
}
    `;
export const CustomerMainInfosFragmentDoc = gql`
    fragment CustomerMainInfos on CustomerDetails {
  id
  name
  externalId
  canBeDeleted
  legalName
  legalNumber
  phone
  email
  currency
  addressLine1
  addressLine2
  state
  country
  city
  zipcode
  paymentProvider
  stripeCustomer {
    id
    providerCustomerId
  }
}
    `;
export const CustomerDetailsFragmentDoc = gql`
    fragment CustomerDetails on CustomerDetails {
  id
  name
  externalId
  canBeDeleted
  hasActiveWallet
  currency
  subscriptions(status: [active, pending]) {
    plan {
      id
      amountCurrency
    }
    ...SubscriptionItem
    ...CustomerUsageSubscription
  }
  invoices {
    ...CustomerInvoiceList
  }
  appliedCoupons {
    ...CustomerCoupon
  }
  appliedAddOns {
    ...CustomerAddOns
  }
  ...CustomerVatRate
  ...AddCustomerDrawerDetail
  ...CustomerMainInfos
}
    ${SubscriptionItemFragmentDoc}
${CustomerUsageSubscriptionFragmentDoc}
${CustomerInvoiceListFragmentDoc}
${CustomerCouponFragmentDoc}
${CustomerAddOnsFragmentDoc}
${CustomerVatRateFragmentDoc}
${AddCustomerDrawerDetailFragmentDoc}
${CustomerMainInfosFragmentDoc}`;
export const EventItemFragmentDoc = gql`
    fragment EventItem on Event {
  id
  code
  externalCustomerId
  timestamp
  matchBillableMetric
  matchCustomField
}
    `;
export const EventListFragmentDoc = gql`
    fragment EventList on Event {
  id
  code
  externalCustomerId
  transactionId
  timestamp
  receivedAt
  payload
  billableMetricName
  matchBillableMetric
  matchCustomField
  apiClient
  ipAddress
  externalSubscriptionId
  ...EventItem
}
    ${EventItemFragmentDoc}`;
export const OrganizationInformationsFragmentDoc = gql`
    fragment OrganizationInformations on Organization {
  id
  logoUrl
  name
  legalName
  legalNumber
  email
  addressLine1
  addressLine2
  zipcode
  city
  state
  country
}
    `;
export const StripeIntegrationFragmentDoc = gql`
    fragment StripeIntegration on StripeProvider {
  id
  secretKey
  createCustomers
}
    `;
export const OrganizationInvoiceTemplateFragmentDoc = gql`
    fragment OrganizationInvoiceTemplate on Organization {
  invoiceFooter
}
    `;
export const UserIdentifierDocument = gql`
    query UserIdentifier {
  me: currentUser {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;

/**
 * __useUserIdentifierQuery__
 *
 * To run a query within a React component, call `useUserIdentifierQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIdentifierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIdentifierQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserIdentifierQuery(baseOptions?: Apollo.QueryHookOptions<UserIdentifierQuery, UserIdentifierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserIdentifierQuery, UserIdentifierQueryVariables>(UserIdentifierDocument, options);
      }
export function useUserIdentifierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserIdentifierQuery, UserIdentifierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserIdentifierQuery, UserIdentifierQueryVariables>(UserIdentifierDocument, options);
        }
export type UserIdentifierQueryHookResult = ReturnType<typeof useUserIdentifierQuery>;
export type UserIdentifierLazyQueryHookResult = ReturnType<typeof useUserIdentifierLazyQuery>;
export type UserIdentifierQueryResult = Apollo.QueryResult<UserIdentifierQuery, UserIdentifierQueryVariables>;
export const DeleteAddOnDocument = gql`
    mutation deleteAddOn($input: DestroyAddOnInput!) {
  destroyAddOn(input: $input) {
    id
  }
}
    `;
export type DeleteAddOnMutationFn = Apollo.MutationFunction<DeleteAddOnMutation, DeleteAddOnMutationVariables>;

/**
 * __useDeleteAddOnMutation__
 *
 * To run a mutation, you first call `useDeleteAddOnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddOnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddOnMutation, { data, loading, error }] = useDeleteAddOnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAddOnMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAddOnMutation, DeleteAddOnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAddOnMutation, DeleteAddOnMutationVariables>(DeleteAddOnDocument, options);
      }
export type DeleteAddOnMutationHookResult = ReturnType<typeof useDeleteAddOnMutation>;
export type DeleteAddOnMutationResult = Apollo.MutationResult<DeleteAddOnMutation>;
export type DeleteAddOnMutationOptions = Apollo.BaseMutationOptions<DeleteAddOnMutation, DeleteAddOnMutationVariables>;
export const DeleteBillableMetricDocument = gql`
    mutation deleteBillableMetric($input: DestroyBillableMetricInput!) {
  destroyBillableMetric(input: $input) {
    id
  }
}
    `;
export type DeleteBillableMetricMutationFn = Apollo.MutationFunction<DeleteBillableMetricMutation, DeleteBillableMetricMutationVariables>;

/**
 * __useDeleteBillableMetricMutation__
 *
 * To run a mutation, you first call `useDeleteBillableMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBillableMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBillableMetricMutation, { data, loading, error }] = useDeleteBillableMetricMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBillableMetricMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBillableMetricMutation, DeleteBillableMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBillableMetricMutation, DeleteBillableMetricMutationVariables>(DeleteBillableMetricDocument, options);
      }
export type DeleteBillableMetricMutationHookResult = ReturnType<typeof useDeleteBillableMetricMutation>;
export type DeleteBillableMetricMutationResult = Apollo.MutationResult<DeleteBillableMetricMutation>;
export type DeleteBillableMetricMutationOptions = Apollo.BaseMutationOptions<DeleteBillableMetricMutation, DeleteBillableMetricMutationVariables>;
export const DeleteCouponDocument = gql`
    mutation deleteCoupon($input: DestroyCouponInput!) {
  destroyCoupon(input: $input) {
    id
  }
}
    `;
export type DeleteCouponMutationFn = Apollo.MutationFunction<DeleteCouponMutation, DeleteCouponMutationVariables>;

/**
 * __useDeleteCouponMutation__
 *
 * To run a mutation, you first call `useDeleteCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCouponMutation, { data, loading, error }] = useDeleteCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCouponMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCouponMutation, DeleteCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCouponMutation, DeleteCouponMutationVariables>(DeleteCouponDocument, options);
      }
export type DeleteCouponMutationHookResult = ReturnType<typeof useDeleteCouponMutation>;
export type DeleteCouponMutationResult = Apollo.MutationResult<DeleteCouponMutation>;
export type DeleteCouponMutationOptions = Apollo.BaseMutationOptions<DeleteCouponMutation, DeleteCouponMutationVariables>;
export const TerminateCouponDocument = gql`
    mutation terminateCoupon($input: TerminateCouponInput!) {
  terminateCoupon(input: $input) {
    id
  }
}
    `;
export type TerminateCouponMutationFn = Apollo.MutationFunction<TerminateCouponMutation, TerminateCouponMutationVariables>;

/**
 * __useTerminateCouponMutation__
 *
 * To run a mutation, you first call `useTerminateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateCouponMutation, { data, loading, error }] = useTerminateCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTerminateCouponMutation(baseOptions?: Apollo.MutationHookOptions<TerminateCouponMutation, TerminateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TerminateCouponMutation, TerminateCouponMutationVariables>(TerminateCouponDocument, options);
      }
export type TerminateCouponMutationHookResult = ReturnType<typeof useTerminateCouponMutation>;
export type TerminateCouponMutationResult = Apollo.MutationResult<TerminateCouponMutation>;
export type TerminateCouponMutationOptions = Apollo.BaseMutationOptions<TerminateCouponMutation, TerminateCouponMutationVariables>;
export const GetAddOnsForCustomerDocument = gql`
    query getAddOnsForCustomer($page: Int, $limit: Int) {
  addOns(page: $page, limit: $limit) {
    metadata {
      currentPage
      totalPages
    }
    collection {
      id
      name
      amountCurrency
      amountCents
    }
  }
}
    `;

/**
 * __useGetAddOnsForCustomerQuery__
 *
 * To run a query within a React component, call `useGetAddOnsForCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddOnsForCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddOnsForCustomerQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAddOnsForCustomerQuery(baseOptions?: Apollo.QueryHookOptions<GetAddOnsForCustomerQuery, GetAddOnsForCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAddOnsForCustomerQuery, GetAddOnsForCustomerQueryVariables>(GetAddOnsForCustomerDocument, options);
      }
export function useGetAddOnsForCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddOnsForCustomerQuery, GetAddOnsForCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAddOnsForCustomerQuery, GetAddOnsForCustomerQueryVariables>(GetAddOnsForCustomerDocument, options);
        }
export type GetAddOnsForCustomerQueryHookResult = ReturnType<typeof useGetAddOnsForCustomerQuery>;
export type GetAddOnsForCustomerLazyQueryHookResult = ReturnType<typeof useGetAddOnsForCustomerLazyQuery>;
export type GetAddOnsForCustomerQueryResult = Apollo.QueryResult<GetAddOnsForCustomerQuery, GetAddOnsForCustomerQueryVariables>;
export const AddAddOnDocument = gql`
    mutation addAddOn($input: CreateAppliedAddOnInput!) {
  createAppliedAddOn(input: $input) {
    id
  }
}
    `;
export type AddAddOnMutationFn = Apollo.MutationFunction<AddAddOnMutation, AddAddOnMutationVariables>;

/**
 * __useAddAddOnMutation__
 *
 * To run a mutation, you first call `useAddAddOnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAddOnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAddOnMutation, { data, loading, error }] = useAddAddOnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAddOnMutation(baseOptions?: Apollo.MutationHookOptions<AddAddOnMutation, AddAddOnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAddOnMutation, AddAddOnMutationVariables>(AddAddOnDocument, options);
      }
export type AddAddOnMutationHookResult = ReturnType<typeof useAddAddOnMutation>;
export type AddAddOnMutationResult = Apollo.MutationResult<AddAddOnMutation>;
export type AddAddOnMutationOptions = Apollo.BaseMutationOptions<AddAddOnMutation, AddAddOnMutationVariables>;
export const GetCouponForCustomerDocument = gql`
    query getCouponForCustomer($page: Int, $limit: Int, $status: CouponStatusEnum) {
  coupons(page: $page, limit: $limit, status: $status) {
    metadata {
      currentPage
      totalPages
    }
    collection {
      id
      name
      amountCurrency
      amountCents
      couponType
      percentageRate
      frequency
      frequencyDuration
      ...CouponCaption
    }
  }
}
    ${CouponCaptionFragmentDoc}`;

/**
 * __useGetCouponForCustomerQuery__
 *
 * To run a query within a React component, call `useGetCouponForCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponForCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponForCustomerQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetCouponForCustomerQuery(baseOptions?: Apollo.QueryHookOptions<GetCouponForCustomerQuery, GetCouponForCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCouponForCustomerQuery, GetCouponForCustomerQueryVariables>(GetCouponForCustomerDocument, options);
      }
export function useGetCouponForCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCouponForCustomerQuery, GetCouponForCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCouponForCustomerQuery, GetCouponForCustomerQueryVariables>(GetCouponForCustomerDocument, options);
        }
export type GetCouponForCustomerQueryHookResult = ReturnType<typeof useGetCouponForCustomerQuery>;
export type GetCouponForCustomerLazyQueryHookResult = ReturnType<typeof useGetCouponForCustomerLazyQuery>;
export type GetCouponForCustomerQueryResult = Apollo.QueryResult<GetCouponForCustomerQuery, GetCouponForCustomerQueryVariables>;
export const AddCouponDocument = gql`
    mutation addCoupon($input: CreateAppliedCouponInput!) {
  createAppliedCoupon(input: $input) {
    id
  }
}
    `;
export type AddCouponMutationFn = Apollo.MutationFunction<AddCouponMutation, AddCouponMutationVariables>;

/**
 * __useAddCouponMutation__
 *
 * To run a mutation, you first call `useAddCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCouponMutation, { data, loading, error }] = useAddCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCouponMutation(baseOptions?: Apollo.MutationHookOptions<AddCouponMutation, AddCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCouponMutation, AddCouponMutationVariables>(AddCouponDocument, options);
      }
export type AddCouponMutationHookResult = ReturnType<typeof useAddCouponMutation>;
export type AddCouponMutationResult = Apollo.MutationResult<AddCouponMutation>;
export type AddCouponMutationOptions = Apollo.BaseMutationOptions<AddCouponMutation, AddCouponMutationVariables>;
export const RemoveCouponDocument = gql`
    mutation removeCoupon($input: TerminateAppliedCouponInput!) {
  terminateAppliedCoupon(input: $input) {
    id
  }
}
    `;
export type RemoveCouponMutationFn = Apollo.MutationFunction<RemoveCouponMutation, RemoveCouponMutationVariables>;

/**
 * __useRemoveCouponMutation__
 *
 * To run a mutation, you first call `useRemoveCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCouponMutation, { data, loading, error }] = useRemoveCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCouponMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCouponMutation, RemoveCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCouponMutation, RemoveCouponMutationVariables>(RemoveCouponDocument, options);
      }
export type RemoveCouponMutationHookResult = ReturnType<typeof useRemoveCouponMutation>;
export type RemoveCouponMutationResult = Apollo.MutationResult<RemoveCouponMutation>;
export type RemoveCouponMutationOptions = Apollo.BaseMutationOptions<RemoveCouponMutation, RemoveCouponMutationVariables>;
export const DownloadInvoiceDocument = gql`
    mutation downloadInvoice($input: DownloadInvoiceInput!) {
  downloadInvoice(input: $input) {
    id
    fileUrl
  }
}
    `;
export type DownloadInvoiceMutationFn = Apollo.MutationFunction<DownloadInvoiceMutation, DownloadInvoiceMutationVariables>;

/**
 * __useDownloadInvoiceMutation__
 *
 * To run a mutation, you first call `useDownloadInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadInvoiceMutation, { data, loading, error }] = useDownloadInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<DownloadInvoiceMutation, DownloadInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadInvoiceMutation, DownloadInvoiceMutationVariables>(DownloadInvoiceDocument, options);
      }
export type DownloadInvoiceMutationHookResult = ReturnType<typeof useDownloadInvoiceMutation>;
export type DownloadInvoiceMutationResult = Apollo.MutationResult<DownloadInvoiceMutation>;
export type DownloadInvoiceMutationOptions = Apollo.BaseMutationOptions<DownloadInvoiceMutation, DownloadInvoiceMutationVariables>;
export const DeleteCustomerDocument = gql`
    mutation deleteCustomer($input: DestroyCustomerInput!) {
  destroyCustomer(input: $input) {
    id
  }
}
    `;
export type DeleteCustomerMutationFn = Apollo.MutationFunction<DeleteCustomerMutation, DeleteCustomerMutationVariables>;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(DeleteCustomerDocument, options);
      }
export type DeleteCustomerMutationHookResult = ReturnType<typeof useDeleteCustomerMutation>;
export type DeleteCustomerMutationResult = Apollo.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>;
export const DeleteCustomerVatRateDocument = gql`
    mutation deleteCustomerVatRate($input: UpdateCustomerVatRateInput!) {
  updateCustomerVatRate(input: $input) {
    id
    vatRate
  }
}
    `;
export type DeleteCustomerVatRateMutationFn = Apollo.MutationFunction<DeleteCustomerVatRateMutation, DeleteCustomerVatRateMutationVariables>;

/**
 * __useDeleteCustomerVatRateMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerVatRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerVatRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerVatRateMutation, { data, loading, error }] = useDeleteCustomerVatRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomerVatRateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerVatRateMutation, DeleteCustomerVatRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerVatRateMutation, DeleteCustomerVatRateMutationVariables>(DeleteCustomerVatRateDocument, options);
      }
export type DeleteCustomerVatRateMutationHookResult = ReturnType<typeof useDeleteCustomerVatRateMutation>;
export type DeleteCustomerVatRateMutationResult = Apollo.MutationResult<DeleteCustomerVatRateMutation>;
export type DeleteCustomerVatRateMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerVatRateMutation, DeleteCustomerVatRateMutationVariables>;
export const UpdateCustomerVatRateDocument = gql`
    mutation updateCustomerVatRate($input: UpdateCustomerVatRateInput!) {
  updateCustomerVatRate(input: $input) {
    id
    vatRate
  }
}
    `;
export type UpdateCustomerVatRateMutationFn = Apollo.MutationFunction<UpdateCustomerVatRateMutation, UpdateCustomerVatRateMutationVariables>;

/**
 * __useUpdateCustomerVatRateMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerVatRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerVatRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerVatRateMutation, { data, loading, error }] = useUpdateCustomerVatRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerVatRateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerVatRateMutation, UpdateCustomerVatRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerVatRateMutation, UpdateCustomerVatRateMutationVariables>(UpdateCustomerVatRateDocument, options);
      }
export type UpdateCustomerVatRateMutationHookResult = ReturnType<typeof useUpdateCustomerVatRateMutation>;
export type UpdateCustomerVatRateMutationResult = Apollo.MutationResult<UpdateCustomerVatRateMutation>;
export type UpdateCustomerVatRateMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerVatRateMutation, UpdateCustomerVatRateMutationVariables>;
export const UpdateCustomerSubscriptionDocument = gql`
    mutation updateCustomerSubscription($input: UpdateSubscriptionInput!) {
  updateSubscription(input: $input) {
    id
    name
    status
    startedAt
    subscriptionDate
  }
}
    `;
export type UpdateCustomerSubscriptionMutationFn = Apollo.MutationFunction<UpdateCustomerSubscriptionMutation, UpdateCustomerSubscriptionMutationVariables>;

/**
 * __useUpdateCustomerSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerSubscriptionMutation, { data, loading, error }] = useUpdateCustomerSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerSubscriptionMutation, UpdateCustomerSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerSubscriptionMutation, UpdateCustomerSubscriptionMutationVariables>(UpdateCustomerSubscriptionDocument, options);
      }
export type UpdateCustomerSubscriptionMutationHookResult = ReturnType<typeof useUpdateCustomerSubscriptionMutation>;
export type UpdateCustomerSubscriptionMutationResult = Apollo.MutationResult<UpdateCustomerSubscriptionMutation>;
export type UpdateCustomerSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerSubscriptionMutation, UpdateCustomerSubscriptionMutationVariables>;
export const TerminateCustomerSubscriptionDocument = gql`
    mutation terminateCustomerSubscription($input: TerminateSubscriptionInput!) {
  terminateSubscription(input: $input) {
    id
  }
}
    `;
export type TerminateCustomerSubscriptionMutationFn = Apollo.MutationFunction<TerminateCustomerSubscriptionMutation, TerminateCustomerSubscriptionMutationVariables>;

/**
 * __useTerminateCustomerSubscriptionMutation__
 *
 * To run a mutation, you first call `useTerminateCustomerSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateCustomerSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateCustomerSubscriptionMutation, { data, loading, error }] = useTerminateCustomerSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTerminateCustomerSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<TerminateCustomerSubscriptionMutation, TerminateCustomerSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TerminateCustomerSubscriptionMutation, TerminateCustomerSubscriptionMutationVariables>(TerminateCustomerSubscriptionDocument, options);
      }
export type TerminateCustomerSubscriptionMutationHookResult = ReturnType<typeof useTerminateCustomerSubscriptionMutation>;
export type TerminateCustomerSubscriptionMutationResult = Apollo.MutationResult<TerminateCustomerSubscriptionMutation>;
export type TerminateCustomerSubscriptionMutationOptions = Apollo.BaseMutationOptions<TerminateCustomerSubscriptionMutation, TerminateCustomerSubscriptionMutationVariables>;
export const CustomerUsageDocument = gql`
    query customerUsage($customerId: ID!, $subscriptionId: ID!) {
  customerUsage(customerId: $customerId, subscriptionId: $subscriptionId) {
    amountCents
    amountCurrency
    fromDate
    toDate
    chargesUsage {
      units
      amountCents
      billableMetric {
        id
        code
        name
      }
    }
    ...CustomerUsageForUsageDetails
  }
}
    ${CustomerUsageForUsageDetailsFragmentDoc}`;

/**
 * __useCustomerUsageQuery__
 *
 * To run a query within a React component, call `useCustomerUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerUsageQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useCustomerUsageQuery(baseOptions: Apollo.QueryHookOptions<CustomerUsageQuery, CustomerUsageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerUsageQuery, CustomerUsageQueryVariables>(CustomerUsageDocument, options);
      }
export function useCustomerUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerUsageQuery, CustomerUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerUsageQuery, CustomerUsageQueryVariables>(CustomerUsageDocument, options);
        }
export type CustomerUsageQueryHookResult = ReturnType<typeof useCustomerUsageQuery>;
export type CustomerUsageLazyQueryHookResult = ReturnType<typeof useCustomerUsageLazyQuery>;
export type CustomerUsageQueryResult = Apollo.QueryResult<CustomerUsageQuery, CustomerUsageQueryVariables>;
export const DeleteWebhookDocument = gql`
    mutation deleteWebhook($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    id
    webhookUrl
  }
}
    `;
export type DeleteWebhookMutationFn = Apollo.MutationFunction<DeleteWebhookMutation, DeleteWebhookMutationVariables>;

/**
 * __useDeleteWebhookMutation__
 *
 * To run a mutation, you first call `useDeleteWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebhookMutation, { data, loading, error }] = useDeleteWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWebhookMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWebhookMutation, DeleteWebhookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWebhookMutation, DeleteWebhookMutationVariables>(DeleteWebhookDocument, options);
      }
export type DeleteWebhookMutationHookResult = ReturnType<typeof useDeleteWebhookMutation>;
export type DeleteWebhookMutationResult = Apollo.MutationResult<DeleteWebhookMutation>;
export type DeleteWebhookMutationOptions = Apollo.BaseMutationOptions<DeleteWebhookMutation, DeleteWebhookMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation updateOrganization($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    id
    webhookUrl
  }
}
    `;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, options);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const GetbillableMetricsDocument = gql`
    query getbillableMetrics($page: Int, $limit: Int) {
  billableMetrics(page: $page, limit: $limit) {
    collection {
      ...billableMetricForPlan
    }
  }
}
    ${BillableMetricForPlanFragmentDoc}`;

/**
 * __useGetbillableMetricsQuery__
 *
 * To run a query within a React component, call `useGetbillableMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetbillableMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetbillableMetricsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetbillableMetricsQuery(baseOptions?: Apollo.QueryHookOptions<GetbillableMetricsQuery, GetbillableMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetbillableMetricsQuery, GetbillableMetricsQueryVariables>(GetbillableMetricsDocument, options);
      }
export function useGetbillableMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetbillableMetricsQuery, GetbillableMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetbillableMetricsQuery, GetbillableMetricsQueryVariables>(GetbillableMetricsDocument, options);
        }
export type GetbillableMetricsQueryHookResult = ReturnType<typeof useGetbillableMetricsQuery>;
export type GetbillableMetricsLazyQueryHookResult = ReturnType<typeof useGetbillableMetricsLazyQuery>;
export type GetbillableMetricsQueryResult = Apollo.QueryResult<GetbillableMetricsQuery, GetbillableMetricsQueryVariables>;
export const DeletePlanDocument = gql`
    mutation deletePlan($input: DestroyPlanInput!) {
  destroyPlan(input: $input) {
    id
  }
}
    `;
export type DeletePlanMutationFn = Apollo.MutationFunction<DeletePlanMutation, DeletePlanMutationVariables>;

/**
 * __useDeletePlanMutation__
 *
 * To run a mutation, you first call `useDeletePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanMutation, { data, loading, error }] = useDeletePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePlanMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlanMutation, DeletePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlanMutation, DeletePlanMutationVariables>(DeletePlanDocument, options);
      }
export type DeletePlanMutationHookResult = ReturnType<typeof useDeletePlanMutation>;
export type DeletePlanMutationResult = Apollo.MutationResult<DeletePlanMutation>;
export type DeletePlanMutationOptions = Apollo.BaseMutationOptions<DeletePlanMutation, DeletePlanMutationVariables>;
export const UpdateOrganizationInformationsDocument = gql`
    mutation updateOrganizationInformations($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    ...OrganizationInformations
    ...EditOrganizationInformationsDialog
  }
}
    ${OrganizationInformationsFragmentDoc}
${EditOrganizationInformationsDialogFragmentDoc}`;
export type UpdateOrganizationInformationsMutationFn = Apollo.MutationFunction<UpdateOrganizationInformationsMutation, UpdateOrganizationInformationsMutationVariables>;

/**
 * __useUpdateOrganizationInformationsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationInformationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationInformationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationInformationsMutation, { data, loading, error }] = useUpdateOrganizationInformationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationInformationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationInformationsMutation, UpdateOrganizationInformationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationInformationsMutation, UpdateOrganizationInformationsMutationVariables>(UpdateOrganizationInformationsDocument, options);
      }
export type UpdateOrganizationInformationsMutationHookResult = ReturnType<typeof useUpdateOrganizationInformationsMutation>;
export type UpdateOrganizationInformationsMutationResult = Apollo.MutationResult<UpdateOrganizationInformationsMutation>;
export type UpdateOrganizationInformationsMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationInformationsMutation, UpdateOrganizationInformationsMutationVariables>;
export const UpdateOrganizationInvoiceTemplateDocument = gql`
    mutation updateOrganizationInvoiceTemplate($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    id
    ...OrganizationInvoiceTemplate
    ...EditOrganizationInvoiceTemplateDialog
  }
}
    ${OrganizationInvoiceTemplateFragmentDoc}
${EditOrganizationInvoiceTemplateDialogFragmentDoc}`;
export type UpdateOrganizationInvoiceTemplateMutationFn = Apollo.MutationFunction<UpdateOrganizationInvoiceTemplateMutation, UpdateOrganizationInvoiceTemplateMutationVariables>;

/**
 * __useUpdateOrganizationInvoiceTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationInvoiceTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationInvoiceTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationInvoiceTemplateMutation, { data, loading, error }] = useUpdateOrganizationInvoiceTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationInvoiceTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationInvoiceTemplateMutation, UpdateOrganizationInvoiceTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationInvoiceTemplateMutation, UpdateOrganizationInvoiceTemplateMutationVariables>(UpdateOrganizationInvoiceTemplateDocument, options);
      }
export type UpdateOrganizationInvoiceTemplateMutationHookResult = ReturnType<typeof useUpdateOrganizationInvoiceTemplateMutation>;
export type UpdateOrganizationInvoiceTemplateMutationResult = Apollo.MutationResult<UpdateOrganizationInvoiceTemplateMutation>;
export type UpdateOrganizationInvoiceTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationInvoiceTemplateMutation, UpdateOrganizationInvoiceTemplateMutationVariables>;
export const UpdateVatRateOrganizationDocument = gql`
    mutation updateVatRateOrganization($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    id
    vatRate
  }
}
    `;
export type UpdateVatRateOrganizationMutationFn = Apollo.MutationFunction<UpdateVatRateOrganizationMutation, UpdateVatRateOrganizationMutationVariables>;

/**
 * __useUpdateVatRateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateVatRateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVatRateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVatRateOrganizationMutation, { data, loading, error }] = useUpdateVatRateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVatRateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVatRateOrganizationMutation, UpdateVatRateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVatRateOrganizationMutation, UpdateVatRateOrganizationMutationVariables>(UpdateVatRateOrganizationDocument, options);
      }
export type UpdateVatRateOrganizationMutationHookResult = ReturnType<typeof useUpdateVatRateOrganizationMutation>;
export type UpdateVatRateOrganizationMutationResult = Apollo.MutationResult<UpdateVatRateOrganizationMutation>;
export type UpdateVatRateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateVatRateOrganizationMutation, UpdateVatRateOrganizationMutationVariables>;
export const AddStripeApiKeyDocument = gql`
    mutation addStripeApiKey($input: AddStripePaymentProviderInput!) {
  addStripePaymentProvider(input: $input) {
    id
    ...StripeIntegration
  }
}
    ${StripeIntegrationFragmentDoc}`;
export type AddStripeApiKeyMutationFn = Apollo.MutationFunction<AddStripeApiKeyMutation, AddStripeApiKeyMutationVariables>;

/**
 * __useAddStripeApiKeyMutation__
 *
 * To run a mutation, you first call `useAddStripeApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStripeApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStripeApiKeyMutation, { data, loading, error }] = useAddStripeApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStripeApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<AddStripeApiKeyMutation, AddStripeApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStripeApiKeyMutation, AddStripeApiKeyMutationVariables>(AddStripeApiKeyDocument, options);
      }
export type AddStripeApiKeyMutationHookResult = ReturnType<typeof useAddStripeApiKeyMutation>;
export type AddStripeApiKeyMutationResult = Apollo.MutationResult<AddStripeApiKeyMutation>;
export type AddStripeApiKeyMutationOptions = Apollo.BaseMutationOptions<AddStripeApiKeyMutation, AddStripeApiKeyMutationVariables>;
export const DeleteStripeDocument = gql`
    mutation deleteStripe($input: DestroyPaymentProviderInput!) {
  destroyPaymentProvider(input: $input) {
    id
  }
}
    `;
export type DeleteStripeMutationFn = Apollo.MutationFunction<DeleteStripeMutation, DeleteStripeMutationVariables>;

/**
 * __useDeleteStripeMutation__
 *
 * To run a mutation, you first call `useDeleteStripeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStripeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStripeMutation, { data, loading, error }] = useDeleteStripeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteStripeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStripeMutation, DeleteStripeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStripeMutation, DeleteStripeMutationVariables>(DeleteStripeDocument, options);
      }
export type DeleteStripeMutationHookResult = ReturnType<typeof useDeleteStripeMutation>;
export type DeleteStripeMutationResult = Apollo.MutationResult<DeleteStripeMutation>;
export type DeleteStripeMutationOptions = Apollo.BaseMutationOptions<DeleteStripeMutation, DeleteStripeMutationVariables>;
export const CreateInviteDocument = gql`
    mutation createInvite($input: CreateInviteInput!) {
  createInvite(input: $input) {
    id
    token
  }
}
    `;
export type CreateInviteMutationFn = Apollo.MutationFunction<CreateInviteMutation, CreateInviteMutationVariables>;

/**
 * __useCreateInviteMutation__
 *
 * To run a mutation, you first call `useCreateInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInviteMutation, { data, loading, error }] = useCreateInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInviteMutation(baseOptions?: Apollo.MutationHookOptions<CreateInviteMutation, CreateInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInviteMutation, CreateInviteMutationVariables>(CreateInviteDocument, options);
      }
export type CreateInviteMutationHookResult = ReturnType<typeof useCreateInviteMutation>;
export type CreateInviteMutationResult = Apollo.MutationResult<CreateInviteMutation>;
export type CreateInviteMutationOptions = Apollo.BaseMutationOptions<CreateInviteMutation, CreateInviteMutationVariables>;
export const RevokeInviteDocument = gql`
    mutation revokeInvite($input: RevokeInviteInput!) {
  revokeInvite(input: $input) {
    id
  }
}
    `;
export type RevokeInviteMutationFn = Apollo.MutationFunction<RevokeInviteMutation, RevokeInviteMutationVariables>;

/**
 * __useRevokeInviteMutation__
 *
 * To run a mutation, you first call `useRevokeInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeInviteMutation, { data, loading, error }] = useRevokeInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeInviteMutation(baseOptions?: Apollo.MutationHookOptions<RevokeInviteMutation, RevokeInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeInviteMutation, RevokeInviteMutationVariables>(RevokeInviteDocument, options);
      }
export type RevokeInviteMutationHookResult = ReturnType<typeof useRevokeInviteMutation>;
export type RevokeInviteMutationResult = Apollo.MutationResult<RevokeInviteMutation>;
export type RevokeInviteMutationOptions = Apollo.BaseMutationOptions<RevokeInviteMutation, RevokeInviteMutationVariables>;
export const RevokeMembershipDocument = gql`
    mutation revokeMembership($input: RevokeMembershipInput!) {
  revokeMembership(input: $input) {
    id
  }
}
    `;
export type RevokeMembershipMutationFn = Apollo.MutationFunction<RevokeMembershipMutation, RevokeMembershipMutationVariables>;

/**
 * __useRevokeMembershipMutation__
 *
 * To run a mutation, you first call `useRevokeMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeMembershipMutation, { data, loading, error }] = useRevokeMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeMembershipMutation(baseOptions?: Apollo.MutationHookOptions<RevokeMembershipMutation, RevokeMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeMembershipMutation, RevokeMembershipMutationVariables>(RevokeMembershipDocument, options);
      }
export type RevokeMembershipMutationHookResult = ReturnType<typeof useRevokeMembershipMutation>;
export type RevokeMembershipMutationResult = Apollo.MutationResult<RevokeMembershipMutation>;
export type RevokeMembershipMutationOptions = Apollo.BaseMutationOptions<RevokeMembershipMutation, RevokeMembershipMutationVariables>;
export const CreateCustomerWalletDocument = gql`
    mutation createCustomerWallet($input: CreateCustomerWalletInput!) {
  createCustomerWallet(input: $input) {
    id
  }
}
    `;
export type CreateCustomerWalletMutationFn = Apollo.MutationFunction<CreateCustomerWalletMutation, CreateCustomerWalletMutationVariables>;

/**
 * __useCreateCustomerWalletMutation__
 *
 * To run a mutation, you first call `useCreateCustomerWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerWalletMutation, { data, loading, error }] = useCreateCustomerWalletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerWalletMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerWalletMutation, CreateCustomerWalletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerWalletMutation, CreateCustomerWalletMutationVariables>(CreateCustomerWalletDocument, options);
      }
export type CreateCustomerWalletMutationHookResult = ReturnType<typeof useCreateCustomerWalletMutation>;
export type CreateCustomerWalletMutationResult = Apollo.MutationResult<CreateCustomerWalletMutation>;
export type CreateCustomerWalletMutationOptions = Apollo.BaseMutationOptions<CreateCustomerWalletMutation, CreateCustomerWalletMutationVariables>;
export const GetCustomerWalletListDocument = gql`
    query getCustomerWalletList($customerId: ID!, $page: Int, $limit: Int) {
  wallets(customerId: $customerId, page: $page, limit: $limit) {
    metadata {
      currentPage
      totalPages
    }
    collection {
      ...WalletForTopup
      ...WalletForUpdate
      ...WalletAccordion
      ...WalletInfosForTransactions
    }
  }
}
    ${WalletForTopupFragmentDoc}
${WalletForUpdateFragmentDoc}
${WalletAccordionFragmentDoc}
${WalletInfosForTransactionsFragmentDoc}`;

/**
 * __useGetCustomerWalletListQuery__
 *
 * To run a query within a React component, call `useGetCustomerWalletListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerWalletListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerWalletListQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCustomerWalletListQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerWalletListQuery, GetCustomerWalletListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerWalletListQuery, GetCustomerWalletListQueryVariables>(GetCustomerWalletListDocument, options);
      }
export function useGetCustomerWalletListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerWalletListQuery, GetCustomerWalletListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerWalletListQuery, GetCustomerWalletListQueryVariables>(GetCustomerWalletListDocument, options);
        }
export type GetCustomerWalletListQueryHookResult = ReturnType<typeof useGetCustomerWalletListQuery>;
export type GetCustomerWalletListLazyQueryHookResult = ReturnType<typeof useGetCustomerWalletListLazyQuery>;
export type GetCustomerWalletListQueryResult = Apollo.QueryResult<GetCustomerWalletListQuery, GetCustomerWalletListQueryVariables>;
export const TerminateCustomerWalletDocument = gql`
    mutation terminateCustomerWallet($input: TerminateCustomerWalletInput!) {
  terminateCustomerWallet(input: $input) {
    id
    status
  }
}
    `;
export type TerminateCustomerWalletMutationFn = Apollo.MutationFunction<TerminateCustomerWalletMutation, TerminateCustomerWalletMutationVariables>;

/**
 * __useTerminateCustomerWalletMutation__
 *
 * To run a mutation, you first call `useTerminateCustomerWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateCustomerWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateCustomerWalletMutation, { data, loading, error }] = useTerminateCustomerWalletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTerminateCustomerWalletMutation(baseOptions?: Apollo.MutationHookOptions<TerminateCustomerWalletMutation, TerminateCustomerWalletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TerminateCustomerWalletMutation, TerminateCustomerWalletMutationVariables>(TerminateCustomerWalletDocument, options);
      }
export type TerminateCustomerWalletMutationHookResult = ReturnType<typeof useTerminateCustomerWalletMutation>;
export type TerminateCustomerWalletMutationResult = Apollo.MutationResult<TerminateCustomerWalletMutation>;
export type TerminateCustomerWalletMutationOptions = Apollo.BaseMutationOptions<TerminateCustomerWalletMutation, TerminateCustomerWalletMutationVariables>;
export const CreateCustomerWalletTransactionDocument = gql`
    mutation createCustomerWalletTransaction($input: CreateCustomerWalletTransactionInput!) {
  createCustomerWalletTransaction(input: $input) {
    collection {
      id
    }
  }
}
    `;
export type CreateCustomerWalletTransactionMutationFn = Apollo.MutationFunction<CreateCustomerWalletTransactionMutation, CreateCustomerWalletTransactionMutationVariables>;

/**
 * __useCreateCustomerWalletTransactionMutation__
 *
 * To run a mutation, you first call `useCreateCustomerWalletTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerWalletTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerWalletTransactionMutation, { data, loading, error }] = useCreateCustomerWalletTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerWalletTransactionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerWalletTransactionMutation, CreateCustomerWalletTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerWalletTransactionMutation, CreateCustomerWalletTransactionMutationVariables>(CreateCustomerWalletTransactionDocument, options);
      }
export type CreateCustomerWalletTransactionMutationHookResult = ReturnType<typeof useCreateCustomerWalletTransactionMutation>;
export type CreateCustomerWalletTransactionMutationResult = Apollo.MutationResult<CreateCustomerWalletTransactionMutation>;
export type CreateCustomerWalletTransactionMutationOptions = Apollo.BaseMutationOptions<CreateCustomerWalletTransactionMutation, CreateCustomerWalletTransactionMutationVariables>;
export const UpdateCustomerWalletDocument = gql`
    mutation updateCustomerWallet($input: UpdateCustomerWalletInput!) {
  updateCustomerWallet(input: $input) {
    ...WalletForUpdate
  }
}
    ${WalletForUpdateFragmentDoc}`;
export type UpdateCustomerWalletMutationFn = Apollo.MutationFunction<UpdateCustomerWalletMutation, UpdateCustomerWalletMutationVariables>;

/**
 * __useUpdateCustomerWalletMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerWalletMutation, { data, loading, error }] = useUpdateCustomerWalletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerWalletMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerWalletMutation, UpdateCustomerWalletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerWalletMutation, UpdateCustomerWalletMutationVariables>(UpdateCustomerWalletDocument, options);
      }
export type UpdateCustomerWalletMutationHookResult = ReturnType<typeof useUpdateCustomerWalletMutation>;
export type UpdateCustomerWalletMutationResult = Apollo.MutationResult<UpdateCustomerWalletMutation>;
export type UpdateCustomerWalletMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerWalletMutation, UpdateCustomerWalletMutationVariables>;
export const GetWalletTransactionsDocument = gql`
    query getWalletTransactions($walletId: ID!, $page: Int, $limit: Int) {
  walletTransactions(walletId: $walletId, page: $page, limit: $limit) {
    metadata {
      currentPage
      totalPages
      totalCount
    }
    collection {
      id
      status
      transactionType
      amount
      creditAmount
      settledAt
      createdAt
    }
  }
}
    `;

/**
 * __useGetWalletTransactionsQuery__
 *
 * To run a query within a React component, call `useGetWalletTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletTransactionsQuery({
 *   variables: {
 *      walletId: // value for 'walletId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetWalletTransactionsQuery(baseOptions: Apollo.QueryHookOptions<GetWalletTransactionsQuery, GetWalletTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWalletTransactionsQuery, GetWalletTransactionsQueryVariables>(GetWalletTransactionsDocument, options);
      }
export function useGetWalletTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWalletTransactionsQuery, GetWalletTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWalletTransactionsQuery, GetWalletTransactionsQueryVariables>(GetWalletTransactionsDocument, options);
        }
export type GetWalletTransactionsQueryHookResult = ReturnType<typeof useGetWalletTransactionsQuery>;
export type GetWalletTransactionsLazyQueryHookResult = ReturnType<typeof useGetWalletTransactionsLazyQuery>;
export type GetWalletTransactionsQueryResult = Apollo.QueryResult<GetWalletTransactionsQuery, GetWalletTransactionsQueryVariables>;
export const GetPlansDocument = gql`
    query getPlans($page: Int, $limit: Int) {
  plans(page: $page, limit: $limit) {
    collection {
      ...AddSubscriptionPlan
    }
  }
}
    ${AddSubscriptionPlanFragmentDoc}`;

/**
 * __useGetPlansQuery__
 *
 * To run a query within a React component, call `useGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlansQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetPlansQuery(baseOptions?: Apollo.QueryHookOptions<GetPlansQuery, GetPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlansQuery, GetPlansQueryVariables>(GetPlansDocument, options);
      }
export function useGetPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlansQuery, GetPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlansQuery, GetPlansQueryVariables>(GetPlansDocument, options);
        }
export type GetPlansQueryHookResult = ReturnType<typeof useGetPlansQuery>;
export type GetPlansLazyQueryHookResult = ReturnType<typeof useGetPlansLazyQuery>;
export type GetPlansQueryResult = Apollo.QueryResult<GetPlansQuery, GetPlansQueryVariables>;
export const CreateSubscriptionDocument = gql`
    mutation createSubscription($input: CreateSubscriptionInput!) {
  createSubscription(input: $input) {
    ...SubscriptionItem
  }
}
    ${SubscriptionItemFragmentDoc}`;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const GetSinglePlanDocument = gql`
    query getSinglePlan($id: ID!) {
  plan(id: $id) {
    ...EditPlan
  }
}
    ${EditPlanFragmentDoc}`;

/**
 * __useGetSinglePlanQuery__
 *
 * To run a query within a React component, call `useGetSinglePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSinglePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSinglePlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSinglePlanQuery(baseOptions: Apollo.QueryHookOptions<GetSinglePlanQuery, GetSinglePlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSinglePlanQuery, GetSinglePlanQueryVariables>(GetSinglePlanDocument, options);
      }
export function useGetSinglePlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSinglePlanQuery, GetSinglePlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSinglePlanQuery, GetSinglePlanQueryVariables>(GetSinglePlanDocument, options);
        }
export type GetSinglePlanQueryHookResult = ReturnType<typeof useGetSinglePlanQuery>;
export type GetSinglePlanLazyQueryHookResult = ReturnType<typeof useGetSinglePlanLazyQuery>;
export type GetSinglePlanQueryResult = Apollo.QueryResult<GetSinglePlanQuery, GetSinglePlanQueryVariables>;
export const CreatePlanDocument = gql`
    mutation createPlan($input: CreatePlanInput!) {
  createPlan(input: $input) {
    id
  }
}
    `;
export type CreatePlanMutationFn = Apollo.MutationFunction<CreatePlanMutation, CreatePlanMutationVariables>;

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlanMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlanMutation, CreatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(CreatePlanDocument, options);
      }
export type CreatePlanMutationHookResult = ReturnType<typeof useCreatePlanMutation>;
export type CreatePlanMutationResult = Apollo.MutationResult<CreatePlanMutation>;
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<CreatePlanMutation, CreatePlanMutationVariables>;
export const UpdatePlanDocument = gql`
    mutation updatePlan($input: UpdatePlanInput!) {
  updatePlan(input: $input) {
    ...PlanItem
    ...DeletePlanDialog
  }
}
    ${PlanItemFragmentDoc}
${DeletePlanDialogFragmentDoc}`;
export type UpdatePlanMutationFn = Apollo.MutationFunction<UpdatePlanMutation, UpdatePlanMutationVariables>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlanMutation, UpdatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(UpdatePlanDocument, options);
      }
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const GetSingleAddOnDocument = gql`
    query getSingleAddOn($id: ID!) {
  addOn(id: $id) {
    ...EditAddOn
  }
}
    ${EditAddOnFragmentDoc}`;

/**
 * __useGetSingleAddOnQuery__
 *
 * To run a query within a React component, call `useGetSingleAddOnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleAddOnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleAddOnQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleAddOnQuery(baseOptions: Apollo.QueryHookOptions<GetSingleAddOnQuery, GetSingleAddOnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleAddOnQuery, GetSingleAddOnQueryVariables>(GetSingleAddOnDocument, options);
      }
export function useGetSingleAddOnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleAddOnQuery, GetSingleAddOnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleAddOnQuery, GetSingleAddOnQueryVariables>(GetSingleAddOnDocument, options);
        }
export type GetSingleAddOnQueryHookResult = ReturnType<typeof useGetSingleAddOnQuery>;
export type GetSingleAddOnLazyQueryHookResult = ReturnType<typeof useGetSingleAddOnLazyQuery>;
export type GetSingleAddOnQueryResult = Apollo.QueryResult<GetSingleAddOnQuery, GetSingleAddOnQueryVariables>;
export const CreateAddOnDocument = gql`
    mutation createAddOn($input: CreateAddOnInput!) {
  createAddOn(input: $input) {
    id
  }
}
    `;
export type CreateAddOnMutationFn = Apollo.MutationFunction<CreateAddOnMutation, CreateAddOnMutationVariables>;

/**
 * __useCreateAddOnMutation__
 *
 * To run a mutation, you first call `useCreateAddOnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddOnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddOnMutation, { data, loading, error }] = useCreateAddOnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAddOnMutation(baseOptions?: Apollo.MutationHookOptions<CreateAddOnMutation, CreateAddOnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAddOnMutation, CreateAddOnMutationVariables>(CreateAddOnDocument, options);
      }
export type CreateAddOnMutationHookResult = ReturnType<typeof useCreateAddOnMutation>;
export type CreateAddOnMutationResult = Apollo.MutationResult<CreateAddOnMutation>;
export type CreateAddOnMutationOptions = Apollo.BaseMutationOptions<CreateAddOnMutation, CreateAddOnMutationVariables>;
export const UpdateAddOnDocument = gql`
    mutation updateAddOn($input: UpdateAddOnInput!) {
  updateAddOn(input: $input) {
    ...AddOnItem
  }
}
    ${AddOnItemFragmentDoc}`;
export type UpdateAddOnMutationFn = Apollo.MutationFunction<UpdateAddOnMutation, UpdateAddOnMutationVariables>;

/**
 * __useUpdateAddOnMutation__
 *
 * To run a mutation, you first call `useUpdateAddOnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddOnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddOnMutation, { data, loading, error }] = useUpdateAddOnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAddOnMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAddOnMutation, UpdateAddOnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAddOnMutation, UpdateAddOnMutationVariables>(UpdateAddOnDocument, options);
      }
export type UpdateAddOnMutationHookResult = ReturnType<typeof useUpdateAddOnMutation>;
export type UpdateAddOnMutationResult = Apollo.MutationResult<UpdateAddOnMutation>;
export type UpdateAddOnMutationOptions = Apollo.BaseMutationOptions<UpdateAddOnMutation, UpdateAddOnMutationVariables>;
export const GetSingleBillableMetricDocument = gql`
    query getSingleBillableMetric($id: ID!) {
  billableMetric(id: $id) {
    ...EditBillableMetric
  }
}
    ${EditBillableMetricFragmentDoc}`;

/**
 * __useGetSingleBillableMetricQuery__
 *
 * To run a query within a React component, call `useGetSingleBillableMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleBillableMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleBillableMetricQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleBillableMetricQuery(baseOptions: Apollo.QueryHookOptions<GetSingleBillableMetricQuery, GetSingleBillableMetricQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleBillableMetricQuery, GetSingleBillableMetricQueryVariables>(GetSingleBillableMetricDocument, options);
      }
export function useGetSingleBillableMetricLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleBillableMetricQuery, GetSingleBillableMetricQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleBillableMetricQuery, GetSingleBillableMetricQueryVariables>(GetSingleBillableMetricDocument, options);
        }
export type GetSingleBillableMetricQueryHookResult = ReturnType<typeof useGetSingleBillableMetricQuery>;
export type GetSingleBillableMetricLazyQueryHookResult = ReturnType<typeof useGetSingleBillableMetricLazyQuery>;
export type GetSingleBillableMetricQueryResult = Apollo.QueryResult<GetSingleBillableMetricQuery, GetSingleBillableMetricQueryVariables>;
export const CreateBillableMetricDocument = gql`
    mutation createBillableMetric($input: CreateBillableMetricInput!) {
  createBillableMetric(input: $input) {
    id
    group
  }
}
    `;
export type CreateBillableMetricMutationFn = Apollo.MutationFunction<CreateBillableMetricMutation, CreateBillableMetricMutationVariables>;

/**
 * __useCreateBillableMetricMutation__
 *
 * To run a mutation, you first call `useCreateBillableMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillableMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillableMetricMutation, { data, loading, error }] = useCreateBillableMetricMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBillableMetricMutation(baseOptions?: Apollo.MutationHookOptions<CreateBillableMetricMutation, CreateBillableMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBillableMetricMutation, CreateBillableMetricMutationVariables>(CreateBillableMetricDocument, options);
      }
export type CreateBillableMetricMutationHookResult = ReturnType<typeof useCreateBillableMetricMutation>;
export type CreateBillableMetricMutationResult = Apollo.MutationResult<CreateBillableMetricMutation>;
export type CreateBillableMetricMutationOptions = Apollo.BaseMutationOptions<CreateBillableMetricMutation, CreateBillableMetricMutationVariables>;
export const UpdateBillableMetricDocument = gql`
    mutation updateBillableMetric($input: UpdateBillableMetricInput!) {
  updateBillableMetric(input: $input) {
    ...BillableMetricItem
    ...DeleteBillableMetricDialog
  }
}
    ${BillableMetricItemFragmentDoc}
${DeleteBillableMetricDialogFragmentDoc}`;
export type UpdateBillableMetricMutationFn = Apollo.MutationFunction<UpdateBillableMetricMutation, UpdateBillableMetricMutationVariables>;

/**
 * __useUpdateBillableMetricMutation__
 *
 * To run a mutation, you first call `useUpdateBillableMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillableMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillableMetricMutation, { data, loading, error }] = useUpdateBillableMetricMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBillableMetricMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBillableMetricMutation, UpdateBillableMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBillableMetricMutation, UpdateBillableMetricMutationVariables>(UpdateBillableMetricDocument, options);
      }
export type UpdateBillableMetricMutationHookResult = ReturnType<typeof useUpdateBillableMetricMutation>;
export type UpdateBillableMetricMutationResult = Apollo.MutationResult<UpdateBillableMetricMutation>;
export type UpdateBillableMetricMutationOptions = Apollo.BaseMutationOptions<UpdateBillableMetricMutation, UpdateBillableMetricMutationVariables>;
export const GetSingleCouponDocument = gql`
    query getSingleCoupon($id: ID!) {
  coupon(id: $id) {
    ...EditCoupon
  }
}
    ${EditCouponFragmentDoc}`;

/**
 * __useGetSingleCouponQuery__
 *
 * To run a query within a React component, call `useGetSingleCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleCouponQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleCouponQuery(baseOptions: Apollo.QueryHookOptions<GetSingleCouponQuery, GetSingleCouponQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleCouponQuery, GetSingleCouponQueryVariables>(GetSingleCouponDocument, options);
      }
export function useGetSingleCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleCouponQuery, GetSingleCouponQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleCouponQuery, GetSingleCouponQueryVariables>(GetSingleCouponDocument, options);
        }
export type GetSingleCouponQueryHookResult = ReturnType<typeof useGetSingleCouponQuery>;
export type GetSingleCouponLazyQueryHookResult = ReturnType<typeof useGetSingleCouponLazyQuery>;
export type GetSingleCouponQueryResult = Apollo.QueryResult<GetSingleCouponQuery, GetSingleCouponQueryVariables>;
export const CreateCouponDocument = gql`
    mutation createCoupon($input: CreateCouponInput!) {
  createCoupon(input: $input) {
    id
  }
}
    `;
export type CreateCouponMutationFn = Apollo.MutationFunction<CreateCouponMutation, CreateCouponMutationVariables>;

/**
 * __useCreateCouponMutation__
 *
 * To run a mutation, you first call `useCreateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponMutation, { data, loading, error }] = useCreateCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCouponMutation(baseOptions?: Apollo.MutationHookOptions<CreateCouponMutation, CreateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCouponMutation, CreateCouponMutationVariables>(CreateCouponDocument, options);
      }
export type CreateCouponMutationHookResult = ReturnType<typeof useCreateCouponMutation>;
export type CreateCouponMutationResult = Apollo.MutationResult<CreateCouponMutation>;
export type CreateCouponMutationOptions = Apollo.BaseMutationOptions<CreateCouponMutation, CreateCouponMutationVariables>;
export const UpdateCouponDocument = gql`
    mutation updateCoupon($input: UpdateCouponInput!) {
  updateCoupon(input: $input) {
    ...CouponItem
  }
}
    ${CouponItemFragmentDoc}`;
export type UpdateCouponMutationFn = Apollo.MutationFunction<UpdateCouponMutation, UpdateCouponMutationVariables>;

/**
 * __useUpdateCouponMutation__
 *
 * To run a mutation, you first call `useUpdateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponMutation, { data, loading, error }] = useUpdateCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCouponMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCouponMutation, UpdateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCouponMutation, UpdateCouponMutationVariables>(UpdateCouponDocument, options);
      }
export type UpdateCouponMutationHookResult = ReturnType<typeof useUpdateCouponMutation>;
export type UpdateCouponMutationResult = Apollo.MutationResult<UpdateCouponMutation>;
export type UpdateCouponMutationOptions = Apollo.BaseMutationOptions<UpdateCouponMutation, UpdateCouponMutationVariables>;
export const CreateCustomerDocument = gql`
    mutation createCustomer($input: CreateCustomerInput!) {
  createCustomer(input: $input) {
    ...AddCustomerDrawer
    ...CustomerItem
  }
}
    ${AddCustomerDrawerFragmentDoc}
${CustomerItemFragmentDoc}`;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($input: UpdateCustomerInput!) {
  updateCustomer(input: $input) {
    ...AddCustomerDrawer
    ...CustomerItem
  }
}
    ${AddCustomerDrawerFragmentDoc}
${CustomerItemFragmentDoc}`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const GetInvoiceDetailsDocument = gql`
    query getInvoiceDetails($id: ID!) {
  invoice(id: $id) {
    id
    number
    status
    totalAmountCents
    totalAmountCurrency
  }
}
    `;

/**
 * __useGetInvoiceDetailsQuery__
 *
 * To run a query within a React component, call `useGetInvoiceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceDetailsQuery, GetInvoiceDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceDetailsQuery, GetInvoiceDetailsQueryVariables>(GetInvoiceDetailsDocument, options);
      }
export function useGetInvoiceDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceDetailsQuery, GetInvoiceDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceDetailsQuery, GetInvoiceDetailsQueryVariables>(GetInvoiceDetailsDocument, options);
        }
export type GetInvoiceDetailsQueryHookResult = ReturnType<typeof useGetInvoiceDetailsQuery>;
export type GetInvoiceDetailsLazyQueryHookResult = ReturnType<typeof useGetInvoiceDetailsLazyQuery>;
export type GetInvoiceDetailsQueryResult = Apollo.QueryResult<GetInvoiceDetailsQuery, GetInvoiceDetailsQueryVariables>;
export const CurrentVersionDocument = gql`
    query CurrentVersion {
  currentVersion {
    githubUrl
    number
  }
}
    `;

/**
 * __useCurrentVersionQuery__
 *
 * To run a query within a React component, call `useCurrentVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentVersionQuery(baseOptions?: Apollo.QueryHookOptions<CurrentVersionQuery, CurrentVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentVersionQuery, CurrentVersionQueryVariables>(CurrentVersionDocument, options);
      }
export function useCurrentVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentVersionQuery, CurrentVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentVersionQuery, CurrentVersionQueryVariables>(CurrentVersionDocument, options);
        }
export type CurrentVersionQueryHookResult = ReturnType<typeof useCurrentVersionQuery>;
export type CurrentVersionLazyQueryHookResult = ReturnType<typeof useCurrentVersionLazyQuery>;
export type CurrentVersionQueryResult = Apollo.QueryResult<CurrentVersionQuery, CurrentVersionQueryVariables>;
export const AddOnsDocument = gql`
    query addOns($page: Int, $limit: Int) {
  addOns(page: $page, limit: $limit) {
    metadata {
      currentPage
      totalPages
    }
    collection {
      id
      ...AddOnItem
    }
  }
}
    ${AddOnItemFragmentDoc}`;

/**
 * __useAddOnsQuery__
 *
 * To run a query within a React component, call `useAddOnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddOnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddOnsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAddOnsQuery(baseOptions?: Apollo.QueryHookOptions<AddOnsQuery, AddOnsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddOnsQuery, AddOnsQueryVariables>(AddOnsDocument, options);
      }
export function useAddOnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddOnsQuery, AddOnsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddOnsQuery, AddOnsQueryVariables>(AddOnsDocument, options);
        }
export type AddOnsQueryHookResult = ReturnType<typeof useAddOnsQuery>;
export type AddOnsLazyQueryHookResult = ReturnType<typeof useAddOnsLazyQuery>;
export type AddOnsQueryResult = Apollo.QueryResult<AddOnsQuery, AddOnsQueryVariables>;
export const BillableMetricsDocument = gql`
    query billableMetrics($page: Int, $limit: Int) {
  billableMetrics(page: $page, limit: $limit) {
    metadata {
      currentPage
      totalPages
    }
    collection {
      ...BillableMetricItem
    }
  }
}
    ${BillableMetricItemFragmentDoc}`;

/**
 * __useBillableMetricsQuery__
 *
 * To run a query within a React component, call `useBillableMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillableMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillableMetricsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useBillableMetricsQuery(baseOptions?: Apollo.QueryHookOptions<BillableMetricsQuery, BillableMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillableMetricsQuery, BillableMetricsQueryVariables>(BillableMetricsDocument, options);
      }
export function useBillableMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillableMetricsQuery, BillableMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillableMetricsQuery, BillableMetricsQueryVariables>(BillableMetricsDocument, options);
        }
export type BillableMetricsQueryHookResult = ReturnType<typeof useBillableMetricsQuery>;
export type BillableMetricsLazyQueryHookResult = ReturnType<typeof useBillableMetricsLazyQuery>;
export type BillableMetricsQueryResult = Apollo.QueryResult<BillableMetricsQuery, BillableMetricsQueryVariables>;
export const CouponsDocument = gql`
    query coupons($page: Int, $limit: Int) {
  coupons(page: $page, limit: $limit) {
    metadata {
      currentPage
      totalPages
    }
    collection {
      ...CouponItem
      ...CouponCaption
    }
  }
}
    ${CouponItemFragmentDoc}
${CouponCaptionFragmentDoc}`;

/**
 * __useCouponsQuery__
 *
 * To run a query within a React component, call `useCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCouponsQuery(baseOptions?: Apollo.QueryHookOptions<CouponsQuery, CouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options);
      }
export function useCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponsQuery, CouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options);
        }
export type CouponsQueryHookResult = ReturnType<typeof useCouponsQuery>;
export type CouponsLazyQueryHookResult = ReturnType<typeof useCouponsLazyQuery>;
export type CouponsQueryResult = Apollo.QueryResult<CouponsQuery, CouponsQueryVariables>;
export const GetCustomerDocument = gql`
    query getCustomer($id: ID!) {
  customer(id: $id) {
    ...CustomerDetails
  }
}
    ${CustomerDetailsFragmentDoc}`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const CustomersDocument = gql`
    query customers($page: Int, $limit: Int) {
  customers(page: $page, limit: $limit) {
    metadata {
      currentPage
      totalPages
    }
    collection {
      ...CustomerItem
    }
  }
}
    ${CustomerItemFragmentDoc}`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCustomersQuery(baseOptions?: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
      }
export function useCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
        }
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;
export const GetinviteDocument = gql`
    query getinvite($token: String!) {
  invite(token: $token) {
    id
    email
    organization {
      id
      name
    }
  }
}
    `;

/**
 * __useGetinviteQuery__
 *
 * To run a query within a React component, call `useGetinviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetinviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetinviteQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetinviteQuery(baseOptions: Apollo.QueryHookOptions<GetinviteQuery, GetinviteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetinviteQuery, GetinviteQueryVariables>(GetinviteDocument, options);
      }
export function useGetinviteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetinviteQuery, GetinviteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetinviteQuery, GetinviteQueryVariables>(GetinviteDocument, options);
        }
export type GetinviteQueryHookResult = ReturnType<typeof useGetinviteQuery>;
export type GetinviteLazyQueryHookResult = ReturnType<typeof useGetinviteLazyQuery>;
export type GetinviteQueryResult = Apollo.QueryResult<GetinviteQuery, GetinviteQueryVariables>;
export const AcceptInviteDocument = gql`
    mutation acceptInvite($input: AcceptInviteInput!) {
  acceptInvite(input: $input) {
    token
    user {
      ...CurrentUser
    }
  }
}
    ${CurrentUserFragmentDoc}`;
export type AcceptInviteMutationFn = Apollo.MutationFunction<AcceptInviteMutation, AcceptInviteMutationVariables>;

/**
 * __useAcceptInviteMutation__
 *
 * To run a mutation, you first call `useAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteMutation, { data, loading, error }] = useAcceptInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptInviteMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInviteMutation, AcceptInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInviteMutation, AcceptInviteMutationVariables>(AcceptInviteDocument, options);
      }
export type AcceptInviteMutationHookResult = ReturnType<typeof useAcceptInviteMutation>;
export type AcceptInviteMutationResult = Apollo.MutationResult<AcceptInviteMutation>;
export type AcceptInviteMutationOptions = Apollo.BaseMutationOptions<AcceptInviteMutation, AcceptInviteMutationVariables>;
export const PlansDocument = gql`
    query plans($page: Int, $limit: Int) {
  plans(page: $page, limit: $limit) {
    metadata {
      currentPage
      totalPages
    }
    collection {
      ...PlanItem
    }
  }
}
    ${PlanItemFragmentDoc}`;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePlansQuery(baseOptions?: Apollo.QueryHookOptions<PlansQuery, PlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
      }
export function usePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
        }
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansQueryResult = Apollo.QueryResult<PlansQuery, PlansQueryVariables>;
export const LoginUserDocument = gql`
    mutation loginUser($input: LoginUserInput!) {
  loginUser(input: $input) {
    user {
      ...CurrentUser
    }
    token
  }
}
    ${CurrentUserFragmentDoc}`;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const SignupDocument = gql`
    mutation signup($input: RegisterUserInput!) {
  registerUser(input: $input) {
    token
    user {
      ...CurrentUser
    }
    organization {
      id
      name
    }
  }
}
    ${CurrentUserFragmentDoc}`;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const EventsDocument = gql`
    query events($page: Int, $limit: Int) {
  events(page: $page, limit: $limit) {
    collection {
      ...EventList
    }
    metadata {
      currentPage
      totalPages
    }
  }
}
    ${EventListFragmentDoc}`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const WehbookSettingDocument = gql`
    query wehbookSetting {
  currentUser {
    id
    organizations {
      id
      webhookUrl
    }
  }
}
    `;

/**
 * __useWehbookSettingQuery__
 *
 * To run a query within a React component, call `useWehbookSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useWehbookSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWehbookSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useWehbookSettingQuery(baseOptions?: Apollo.QueryHookOptions<WehbookSettingQuery, WehbookSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WehbookSettingQuery, WehbookSettingQueryVariables>(WehbookSettingDocument, options);
      }
export function useWehbookSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WehbookSettingQuery, WehbookSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WehbookSettingQuery, WehbookSettingQueryVariables>(WehbookSettingDocument, options);
        }
export type WehbookSettingQueryHookResult = ReturnType<typeof useWehbookSettingQuery>;
export type WehbookSettingLazyQueryHookResult = ReturnType<typeof useWehbookSettingLazyQuery>;
export type WehbookSettingQueryResult = Apollo.QueryResult<WehbookSettingQuery, WehbookSettingQueryVariables>;
export const IntegrationsSettingDocument = gql`
    query integrationsSetting {
  currentUser {
    id
    organizations {
      id
      stripePaymentProvider {
        id
      }
    }
  }
}
    `;

/**
 * __useIntegrationsSettingQuery__
 *
 * To run a query within a React component, call `useIntegrationsSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationsSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationsSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntegrationsSettingQuery(baseOptions?: Apollo.QueryHookOptions<IntegrationsSettingQuery, IntegrationsSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationsSettingQuery, IntegrationsSettingQueryVariables>(IntegrationsSettingDocument, options);
      }
export function useIntegrationsSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationsSettingQuery, IntegrationsSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationsSettingQuery, IntegrationsSettingQueryVariables>(IntegrationsSettingDocument, options);
        }
export type IntegrationsSettingQueryHookResult = ReturnType<typeof useIntegrationsSettingQuery>;
export type IntegrationsSettingLazyQueryHookResult = ReturnType<typeof useIntegrationsSettingLazyQuery>;
export type IntegrationsSettingQueryResult = Apollo.QueryResult<IntegrationsSettingQuery, IntegrationsSettingQueryVariables>;
export const GetInvitesDocument = gql`
    query getInvites($page: Int, $limit: Int) {
  invites(page: $page, limit: $limit) {
    metadata {
      currentPage
      totalPages
      totalCount
    }
    collection {
      ...InviteItem
    }
  }
}
    ${InviteItemFragmentDoc}`;

/**
 * __useGetInvitesQuery__
 *
 * To run a query within a React component, call `useGetInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetInvitesQuery(baseOptions?: Apollo.QueryHookOptions<GetInvitesQuery, GetInvitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvitesQuery, GetInvitesQueryVariables>(GetInvitesDocument, options);
      }
export function useGetInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvitesQuery, GetInvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvitesQuery, GetInvitesQueryVariables>(GetInvitesDocument, options);
        }
export type GetInvitesQueryHookResult = ReturnType<typeof useGetInvitesQuery>;
export type GetInvitesLazyQueryHookResult = ReturnType<typeof useGetInvitesLazyQuery>;
export type GetInvitesQueryResult = Apollo.QueryResult<GetInvitesQuery, GetInvitesQueryVariables>;
export const GetMembersDocument = gql`
    query getMembers($page: Int, $limit: Int) {
  memberships(page: $page, limit: $limit) {
    metadata {
      currentPage
      totalPages
      totalCount
    }
    collection {
      ...MembershipItem
    }
  }
}
    ${MembershipItemFragmentDoc}`;

/**
 * __useGetMembersQuery__
 *
 * To run a query within a React component, call `useGetMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options);
      }
export function useGetMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options);
        }
export type GetMembersQueryHookResult = ReturnType<typeof useGetMembersQuery>;
export type GetMembersLazyQueryHookResult = ReturnType<typeof useGetMembersLazyQuery>;
export type GetMembersQueryResult = Apollo.QueryResult<GetMembersQuery, GetMembersQueryVariables>;
export const GetOrganizationInformationsDocument = gql`
    query getOrganizationInformations {
  currentUser {
    organizations {
      ...OrganizationInformations
      ...EditOrganizationInformationsDialog
    }
  }
}
    ${OrganizationInformationsFragmentDoc}
${EditOrganizationInformationsDialogFragmentDoc}`;

/**
 * __useGetOrganizationInformationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationInformationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationInformationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationInformationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationInformationsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationInformationsQuery, GetOrganizationInformationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationInformationsQuery, GetOrganizationInformationsQueryVariables>(GetOrganizationInformationsDocument, options);
      }
export function useGetOrganizationInformationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationInformationsQuery, GetOrganizationInformationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationInformationsQuery, GetOrganizationInformationsQueryVariables>(GetOrganizationInformationsDocument, options);
        }
export type GetOrganizationInformationsQueryHookResult = ReturnType<typeof useGetOrganizationInformationsQuery>;
export type GetOrganizationInformationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationInformationsLazyQuery>;
export type GetOrganizationInformationsQueryResult = Apollo.QueryResult<GetOrganizationInformationsQuery, GetOrganizationInformationsQueryVariables>;
export const StripeIntegrationsSettingDocument = gql`
    query stripeIntegrationsSetting {
  currentUser {
    id
    organizations {
      id
      stripePaymentProvider {
        ...StripeIntegration
      }
    }
  }
}
    ${StripeIntegrationFragmentDoc}`;

/**
 * __useStripeIntegrationsSettingQuery__
 *
 * To run a query within a React component, call `useStripeIntegrationsSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeIntegrationsSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeIntegrationsSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeIntegrationsSettingQuery(baseOptions?: Apollo.QueryHookOptions<StripeIntegrationsSettingQuery, StripeIntegrationsSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripeIntegrationsSettingQuery, StripeIntegrationsSettingQueryVariables>(StripeIntegrationsSettingDocument, options);
      }
export function useStripeIntegrationsSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeIntegrationsSettingQuery, StripeIntegrationsSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripeIntegrationsSettingQuery, StripeIntegrationsSettingQueryVariables>(StripeIntegrationsSettingDocument, options);
        }
export type StripeIntegrationsSettingQueryHookResult = ReturnType<typeof useStripeIntegrationsSettingQuery>;
export type StripeIntegrationsSettingLazyQueryHookResult = ReturnType<typeof useStripeIntegrationsSettingLazyQuery>;
export type StripeIntegrationsSettingQueryResult = Apollo.QueryResult<StripeIntegrationsSettingQuery, StripeIntegrationsSettingQueryVariables>;
export const UpdateStripeIntegrationDocument = gql`
    mutation updateStripeIntegration($input: AddStripePaymentProviderInput!) {
  addStripePaymentProvider(input: $input) {
    ...StripeIntegration
  }
}
    ${StripeIntegrationFragmentDoc}`;
export type UpdateStripeIntegrationMutationFn = Apollo.MutationFunction<UpdateStripeIntegrationMutation, UpdateStripeIntegrationMutationVariables>;

/**
 * __useUpdateStripeIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateStripeIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStripeIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStripeIntegrationMutation, { data, loading, error }] = useUpdateStripeIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStripeIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStripeIntegrationMutation, UpdateStripeIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStripeIntegrationMutation, UpdateStripeIntegrationMutationVariables>(UpdateStripeIntegrationDocument, options);
      }
export type UpdateStripeIntegrationMutationHookResult = ReturnType<typeof useUpdateStripeIntegrationMutation>;
export type UpdateStripeIntegrationMutationResult = Apollo.MutationResult<UpdateStripeIntegrationMutation>;
export type UpdateStripeIntegrationMutationOptions = Apollo.BaseMutationOptions<UpdateStripeIntegrationMutation, UpdateStripeIntegrationMutationVariables>;
export const GetOrganizationInvoiceAndTaxInformationsDocument = gql`
    query getOrganizationInvoiceAndTaxInformations {
  currentUser {
    id
    organizations {
      id
      vatRate
      ...OrganizationInvoiceTemplate
      ...EditOrganizationInvoiceTemplateDialog
    }
  }
}
    ${OrganizationInvoiceTemplateFragmentDoc}
${EditOrganizationInvoiceTemplateDialogFragmentDoc}`;

/**
 * __useGetOrganizationInvoiceAndTaxInformationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationInvoiceAndTaxInformationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationInvoiceAndTaxInformationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationInvoiceAndTaxInformationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationInvoiceAndTaxInformationsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationInvoiceAndTaxInformationsQuery, GetOrganizationInvoiceAndTaxInformationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationInvoiceAndTaxInformationsQuery, GetOrganizationInvoiceAndTaxInformationsQueryVariables>(GetOrganizationInvoiceAndTaxInformationsDocument, options);
      }
export function useGetOrganizationInvoiceAndTaxInformationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationInvoiceAndTaxInformationsQuery, GetOrganizationInvoiceAndTaxInformationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationInvoiceAndTaxInformationsQuery, GetOrganizationInvoiceAndTaxInformationsQueryVariables>(GetOrganizationInvoiceAndTaxInformationsDocument, options);
        }
export type GetOrganizationInvoiceAndTaxInformationsQueryHookResult = ReturnType<typeof useGetOrganizationInvoiceAndTaxInformationsQuery>;
export type GetOrganizationInvoiceAndTaxInformationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationInvoiceAndTaxInformationsLazyQuery>;
export type GetOrganizationInvoiceAndTaxInformationsQueryResult = Apollo.QueryResult<GetOrganizationInvoiceAndTaxInformationsQuery, GetOrganizationInvoiceAndTaxInformationsQueryVariables>;
export const GetAllInvoiceDetailsDocument = gql`
    query getAllInvoiceDetails($id: ID!) {
  invoice(id: $id) {
    id
    number
    issuingDate
    vatAmountCents
    vatAmountCurrency
    totalAmountCents
    totalAmountCurrency
    walletTransactionAmountCents
    subtotalBeforePrepaidCredits
    creditAmountCents
    creditAmountCurrency
    customer {
      id
      currency
      name
      legalName
      email
      addressLine1
      addressLine2
      state
      country
      city
      zipcode
    }
    invoiceSubscriptions {
      subscription {
        id
        name
        subscriptionDate
        periodEndDate
        plan {
          id
          name
          interval
          amountCents
          amountCurrency
        }
      }
      fees {
        id
        amountCents
        eventsCount
        units
        feeType
        charge {
          id
          billableMetric {
            id
            name
            aggregationType
          }
        }
        group {
          id
          key
          value
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllInvoiceDetailsQuery__
 *
 * To run a query within a React component, call `useGetAllInvoiceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInvoiceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInvoiceDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAllInvoiceDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetAllInvoiceDetailsQuery, GetAllInvoiceDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllInvoiceDetailsQuery, GetAllInvoiceDetailsQueryVariables>(GetAllInvoiceDetailsDocument, options);
      }
export function useGetAllInvoiceDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInvoiceDetailsQuery, GetAllInvoiceDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllInvoiceDetailsQuery, GetAllInvoiceDetailsQueryVariables>(GetAllInvoiceDetailsDocument, options);
        }
export type GetAllInvoiceDetailsQueryHookResult = ReturnType<typeof useGetAllInvoiceDetailsQuery>;
export type GetAllInvoiceDetailsLazyQueryHookResult = ReturnType<typeof useGetAllInvoiceDetailsLazyQuery>;
export type GetAllInvoiceDetailsQueryResult = Apollo.QueryResult<GetAllInvoiceDetailsQuery, GetAllInvoiceDetailsQueryVariables>;